import Chat from "../../../../assets/Chat";
import Clients from "../../../../assets/Clients";
import Dashboard from "../../../../assets/Dashboard";
import Document from "../../../../assets/Document";
// import Invoice from "../../../../assets/Invoice";
import Pipeline from "../../../../assets/Pipeline";
import Files from "../../../../assets/Files";
import Team from "../../../../assets/Team";
import Templates from "../../../../assets/Templates";
import Disc from "../../../../assets/Disc";
import Setting from "../../../../assets/Setting";

export const sidebarData = [
  {
    title: "Dashboard",
    icon: <Dashboard />,
    url: "/dashboard",
    activeUrl: ["/dashboard"],
  },
  {
    title: "Files",
    icon: <Files />,
    url: "/files",
    activeUrl: ["/files"],
  },
  // {
  //     title: 'Documents',
  //     icon: <Document />,
  //     url: '/documents',
  //     activeUrl: ['/documents', '/documents_trash']
  // },
  {
    title: "Team",
    icon: <Team />,
    url: "/team",
    activeUrl: ["/team"],
  },
  {
    title: "Clients",
    icon: <Clients />,
    url: "/clients",
    activeUrl: ["/clients", "/client_details"],
  },
  {
    title: "Pipeline",
    icon: <Pipeline />,
    url: "/pipeline",
    activeUrl: ["/pipeline", "/task", "/task_detail"],
  },
  // {
  //     title: 'Invoice',
  //     icon: <Invoice />,
  //     url: '/invoice',
  //     activeUrl: ['/invoice']
  // },
  {
    title: "Chat",
    icon: <Chat />,
    url: "/chat",
    activeUrl: ["/chat"],
  },
  {
    title: "Templates",
    icon: <Templates />,
    activeUrl: ["/services"],
    children: [
      {
        title: "Services",
        icon: <Disc />,
        url: "/services",
        activeUrl: ["/services"],
      },
    ],
  },
  {
    title: "Settings",
    icon: <Setting />,
    activeUrl: ["/tags"],
    children: [
      {
        title: "Tags",
        icon: <Disc />,
        url: "/tags",
        activeUrl: ["/tags"],
      },
    ],
  },
];
