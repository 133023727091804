import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Avatar, Form, Input, Spin } from "antd";
import toast from "react-hot-toast";
import ModalComponent from "../../../components/global/modals/ModalComponent";
import LoadableButton from "../../../components/button/LoadableButton";
import Add from "../../../assets/Add";
import {
  AddSectionApi,
  GetAllSectionsApi,
  ReOrderSectionApi,
} from "../../../api/request/service";
import { useParams } from "react-router-dom";

const Section = ({ selectedSection, setSelectedSection }) => {
  const [form] = Form.useForm();
  const { serviceTypeId } = useParams();
  const initialValues = { section_name: "" };
  const [sections, setSections] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [sectionLoading, setSectionLoading] = useState(false);

  useEffect(() => {
    if (selectedSection === null) getSections();
    setSections((prevSection) =>
      prevSection.map((item) =>
        item.id === selectedSection?.id ? selectedSection : item
      )
    );
  }, [selectedSection]);

  useEffect(() => {
    form.resetFields();
  }, [isOpen]);

  const handleOnDragEnd = async (result) => {
    if (!result.destination) return;
    const items = Array.from(sections);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setSections(items);
    try {
      let params = {
        section_id: result?.draggableId,
        section_order: result?.destination?.index + 1,
      };
      await ReOrderSectionApi(params);
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      }
    }
  };

  const addSection = async (value) => {
    setIsLoading(true);
    try {
      const { data } = await AddSectionApi({
        ...value,
        service_type_id: serviceTypeId,
      });
      if (data.status) {
        setIsLoading(false);
        setIsOpen(false);
        getSections();
      }
    } catch (err) {
      setIsLoading(false);
    }
  };

  const getSections = async () => {
    try {
      setSectionLoading(true);
      const { data } = await GetAllSectionsApi({
        service_type_id: serviceTypeId,
      });
      if (data.status) {
        setSections(data?.data);
        setSectionLoading(false);
      }
    } catch (err) {
      setSectionLoading(false);
    }
  };

  return (
    <div className="border rounded-xl bg-White">
      <div className="flex items-center justify-between border-b p-3">
        <p className="font-semibold uppercase text-primary">New Section</p>
        <div onClick={() => setIsOpen(true)}>
          <Add color="#0BAFD4" />
        </div>
      </div>

      <div className="sidebar-container px-3 py-8 ">
        {sectionLoading ? (
          <div className="flex justify-center items-center h-full">
            <Spin />
          </div>
        ) : (
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="sections">
              {(provided) => (
                <div
                  className="section-list"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {sections.map((section, index) => (
                    <Draggable
                      key={section.id}
                      draggableId={section.id}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          className={`section-item mb-4 flex items-center font-semibold rounded-md ${
                            section.id === selectedSection?.id
                              ? "text-primary"
                              : ""
                          }`}
                          onClick={() => setSelectedSection(section)}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <img
                            src="/assets/icons/double-ellipsis-vertical.svg"
                            className="cursor-grab"
                            alt="icon"
                          />
                          <Avatar
                            shape="square"
                            className={`cursor-pointer ${
                              index < 4
                                ? "border-primary border-2 font-semibold bg-transparent text-primary"
                                : "bg-white border-2 border-Gray font-semibold text-Gray"
                            } me-2`}
                          >
                            {index + 1}
                          </Avatar>
                          <p className="line-clamp-1 break-words">
                            {section.section_name}
                          </p>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )}
      </div>

      <ModalComponent
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        title={"Add New Section"}
      >
        <Form
          autoComplete="off"
          form={form}
          initialValues={initialValues}
          onFinish={addSection}
        >
          <Form.Item
            name="section_name"
            rules={[{ required: true, message: "Please enter section name!" }]}
          >
            <Input placeholder="Enter section name..." size="large" />
          </Form.Item>
          <div className="flex justify-center ">
            <LoadableButton
              className="bg-primary text-white text-base font-semibold w-full py-2 rounded-lg mt-2"
              type="submit"
              lable="Submit"
              loadingLable="Adding Section..."
              isLoading={isLoading}
            />
          </div>
        </Form>
      </ModalComponent>
    </div>
  );
};

export default Section;
