import api from "../index";

export const CreateServiceApi = (data) => api.post("addupdateservicetype", data);
export const getAllServiceApi = () => api.post("servicetypelist");
export const getclientlist = () => api.post("getclientlist");
export const UpdateServiceApi = (data) => api.post("addupdateservicetype", data);

export const AddSectionApi = (data) => api.post("addupdatesection", data);
export const GetAllSectionsApi = (id) => api.post("servicesectionlist", id);
export const DeleteSectionApi = (data) => api.post("deleteservicecascade", data);
export const ReOrderSectionApi = (data) => api.post("reorderservicesection", data);

export const AddUpdateQuestionApi = (data) => api.post("addupdatequestion", data);
export const QuestionListApi = (data) => api.post("servicewisequestionlist", data);
export const DeleteQuestionApi = (data) => api.post("deleteservicecascade", data);
export const ReOrderQuestionApi = (data) => api.post("reordersectionquestions", data);

export const GetConditionalQuestionsApi = (data) => api.post("conditionquestionlist", data);

export const GetServiceTaskApi = (data) => api.post('servicetasklist', data);
export const GetTaskWiseFileApi = (data) => api.post('taskwisefile', data);
export const GetFileDetailsApi = (data) => api.post('file-details', data);
export const GetFileAnswerApi = (data) => api.post('filewiseanswerlist', data);
export const ReviewAnswerApi = (data) => api.post('answerreviewcheck', data);
export const UnsubmitFileApi= (data) => api.post('unsubmitfile', data);
export const CompleteTaskApi= (data) => api.post('updatefilesubmitstatus', data);
export const SendInvoiceApi = (data) => api.post('send-invoice', data);
export const SendToSignApi = (data, headers) => api.post('send-to-sign', data, headers);
