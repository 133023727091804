import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Input, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { AddUpdateQuestionApi } from "../../../api/request/service.js";

const MyRadio = ({ question }) => {
  const [value, setValue] = useState(question?.field_label);
  const [options, setOptions] = useState([]);
  const [isCompleted, setIsCompleted] = useState(value?.length ? true : false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (question?.options?.length) {
      const optionsData = question?.options?.map((item, index) => {
        return { id: index + 1, value: item };
      });
      setOptions(optionsData);
    }
  }, [question?.options]);

  const onChange = (event) => {
    setValue(event?.target?.value);
    setIsCompleted(false);
  };

  const addNewOption = () => {
    if (question?.is_editable === 0) return;
    const newId = (options?.length + 1).toString(); // Increment ID
    setOptions([...options, { id: newId, value: "" }]);
    setIsCompleted(false);
  };

  const removeOption = (id) => {
    if (question?.is_editable === 0) return;
    setOptions(options?.filter((option) => option.id !== id));
    setIsCompleted(false);
    handleSubmit(options?.filter((option) => option.id !== id));
  };

  const updateOptionValue = (id, newValue) => {
    if (question?.is_editable === 0) return;
    setIsCompleted(false);
    let updatedArray = options?.map((option) =>
      option.id === id ? { ...option, value: newValue } : option
    );
    setOptions(updatedArray);
  };

  const handleSubmit = async (item) => {
    if (!value) return;
    try {
      setIsLoading(true);
      let questionOption = Array.isArray(item) ? item : options;
      let params = {
        question_id: question?.id,
        section_id: question?.service_section_id,
        field_label: value,
        options: questionOption
          ?.map((item) => item.value)
          ?.filter((item) => item !== null),
      };
      const { data } = await AddUpdateQuestionApi(params);
      if (data?.status) setIsCompleted(true);
      setIsLoading(false);
    } catch (error) {
      if (error?.response?.data?.message)
        toast.error(error?.response?.data?.message);
      setIsLoading(false);
    }
  };

  return (
    <div className="my-2">
      <div className="flex items-center gap-4">
        <img
          src="/assets/icons/double-ellipsis-vertical.svg"
          className="cursor-grab"
          alt="icon"
        />

        <Input
          className="py-3 ps-4"
          size="large"
          placeholder="Radio button"
          onChange={onChange}
          value={value}
          onBlur={handleSubmit}
        />

        <div className="h-[22px] w-[22px]">
          {isLoading ? (
            <Spin
              indicator={<LoadingOutlined spin />}
              className="size-[22px]"
            />
          ) : (
            <div className="size-[22px]">
              {isCompleted ? (
                <img
                  className="size-6"
                  src="/assets/icons/check-circle.svg"
                  alt="icon"
                />
              ) : (
                <img
                  className="size-[22px]"
                  src="/assets/icons/x-circle.svg"
                  alt="icon"
                />
              )}
            </div>
          )}
        </div>
      </div>

      <div className="md:ms-8">
        <div className="my-3">
          {options?.map((option, index) => (
            <div
              key={index}
              className="section-item flex items-center font-semibold rounded-md duration-500"
            >
              <Input
                className="font-medium text-secondaryLight2"
                size="large"
                placeholder="Option"
                value={option?.value}
                onChange={(e) => updateOptionValue(option?.id, e.target.value)}
                onBlur={handleSubmit}
                disabled={question?.is_editable === 0}
              />
              <img
                src={`/assets/icons/${
                  question?.is_editable === 0
                    ? "delete-disable.svg"
                    : "delete.svg"
                }`}
                className="cursor-pointer size-5"
                alt="icon"
                onClick={() => removeOption(option.id)}
              />
            </div>
          ))}
        </div>

        {question?.is_editable !== 0 && (
          <button
            className="cursor-pointer flex gap-3 mt-5"
            onClick={addNewOption}
          >
            <img
              src="/assets/icons/add-circle-black.svg"
              className="size-6"
              alt="icon"
            />
            <p className="font-medium">Add New Option</p>
          </button>
        )}
      </div>
    </div>
  );
};

export default MyRadio;
