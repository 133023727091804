import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Spin } from "antd";
import Add from "../../assets/Add";
import Task from "./components/Task";
import { GetTaskApi, ReOrderTaskApi } from "../../api/request/pipeline";
import toast from "react-hot-toast";

const ViewPipeline = () => {
  const { id } = useParams();
  const { state } = useLocation();
  const pipeline = state?.pipeline;
  const [taskData, setTaskData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [category, setCategory] = useState("pipeline");
  const [mode, setMode] = useState("create");
  const [task, setTask] = useState({});

  const getTaskList = async () => {
    try {
      setIsLoading(true);
      const params = { pipeline_temp_id: id };
      const { data } = await GetTaskApi(params);
      if (data.status) {
        setTaskData(data?.data);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (category === "pipeline") {
      setTask({});
      getTaskList();
    }
  }, [category]);

  const onDragEnd = async (result) => {
    if (!result.destination) return;
    const newItems = Array.from(taskData);
    const [movedItem] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, movedItem);
    setTaskData(newItems);

    try {
      let params = {
        task_id: result?.draggableId,
        task_order: result?.destination?.index + 1,
      };
      await ReOrderTaskApi(params);
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      }
    }
  };

  const handleTask = (mode, data) => {
    if (data) setTask(data);
    setMode(mode);
    setCategory("task");
  };

  return (
    <>
      {category === "pipeline" && (
        <div className="border rounded-lg bg-White">
          <div className="p-4 flex items-center justify-between border-b">
            <h2 className="font-semibold text-xl">{pipeline?.name}</h2>
            <button
              className="bg-secondary flex items-center gap-2 rounded-lg text-White py-2 px-3"
              onClick={() => handleTask("add")}
            >
              <Add />
              <span>Add Task</span>
            </button>
          </div>

          <div className="p-4">
            {isLoading ? (
              <div className="flex flex-col items-center gap-2 my-16">
                <Spin />
                <p className="text-secondaryLight1">Loading...</p>
              </div>
            ) : (
              <>
                {taskData?.length ? (
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                      {(provided) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {taskData?.map((item, index) => (
                            <Draggable
                              key={item.id}
                              draggableId={item.id}
                              index={index}
                            >
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  className="flex items-center gap-3 mb-6"
                                >
                                  <img
                                    src="/assets/icons/move-dnd.svg"
                                    className="size-6"
                                    alt="icon"
                                  />
                                  <div className="w-full flex items-center justify-between gap-6 border rounded-lg p-6">
                                    <div className="flex flex-col gap-3">
                                      <h1 className="font-medium text-xl">
                                        {item?.name}
                                      </h1>
                                      <p className="text-Gray">
                                        Time Duration :{" "}
                                        <span className="text-dark">
                                          {item?.task_duration} Hour
                                        </span>
                                      </p>
                                      <p className="text-Gray">
                                        {item?.description}
                                      </p>
                                      {item?.is_next_payment ? (
                                        <p className="text-Gray">
                                          Verify Payment : <span className="text-dark">Yes</span>
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                    </div>

                                    <div>
                                      <button
                                        className="flex items-center px-4 py-1.5 font-medium border border-secondary text-secondary rounded-lg"
                                        onClick={() => handleTask("edit", item)}
                                      >
                                        <img
                                          src="/assets/icons/edit.svg"
                                          alt="icon"
                                          className="size-4 me-2"
                                        />
                                        Edit
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                ) : (
                  <div className="flex items-center justify-center h-full">
                    <div className="w-1/2 flex flex-col items-center justify-center gap-5">
                      <img
                        src="/assets/images/empty-task.png"
                        className="h-[300px] w-[300px]"
                        alt="Empty task"
                      />
                      <h1 className="text-center font-medium text-3xl mt-5">
                        You have no task
                      </h1>
                      <p className="text-center text-Gray">
                        Using this software, you can ask yourquestions and
                        receive articles using artificial intelligence assistant
                      </p>
                      <button
                        className="bg-secondary flex items-center gap-2 rounded-lg text-White py-2 px-10"
                        onClick={() => handleTask("add")}
                      >
                        <Add />
                        <span>Add Task</span>
                      </button>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      )}

      {category === "task" && (
        <div>
          <Task
            pipelineId={id}
            task={task}
            mode={mode}
            setCategory={setCategory}
          />
        </div>
      )}
    </>
  );
};

export default ViewPipeline;
