import api from '../index'

export const getAllPipelineApi = () => api.post('pipelinetemplist');
export const CreatePipelineApi = (data) => api.post('pipelinetempcreate', data);
export const DeletePipelineApi = (data) => api.post('pipelinetempdelete', data);

export const GetTagApi = () => api.post('taglist');

export const GetTaskApi = (data) => api.post('pipelinetasklist', data);
export const CreateTaskApi = (data) => api.post('addpipelinetask', data);
export const UpdateTaskApi = (data) => api.post('updatepipelinetask', data);
export const ReOrderTaskApi = (data) => api.post('reorderpipelinetask', data);

export const GetSubTaskApi = (data) => api.post('subtasklist', data);
export const CreateSubTaskApi = (data) => api.post('addupdatesubtask', data);
export const DeleteSubTaskApi = (data) => api.post('deletesubtask', data);
export const ReOrderSubTaskApi = (data) => api.post('reorderpipelinesubtask', data);
