import React, { useState } from "react";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import Papa from "papaparse";
import "./Preview.scss";
import * as XLSX from "xlsx";

export default function Preview({ selectedPreview, setSelectedPreview }) {
     console.log(selectedPreview);

     const file = selectedPreview?.path;
     const [csvData, setCsvData] = useState(null);
     const [excelData, setExcelData] = useState(null);

     const renderFilePreview = () => {
          if (!file) {
               return <div>Select a file to preview</div>;
          }

          const fileExtension = file.split(".").pop().toLowerCase();

          switch (fileExtension) {
               case "jpg":
               case "png":
                    return <img src={file} alt="file preview" style={{ maxWidth: "100%", height: "auto" }} />;
               case "pdf":
                    return <iframe src={file} className="w-full h-[calc(100vh-152px)] border-0" />;
               case "doc":
               case "docx":
                    return (
                         <DocViewer
                              className="w-full h-[calc(100vh-152px)]"
                              documents={[{ uri: file }]}
                              pluginRenderers={DocViewerRenderers}
                         />
                    );
               case "csv":
                    Papa.parse(file, {
                         download: true,
                         complete: (result) => setCsvData(result.data),
                    });
                    return (
                         <table>
                              <tbody>
                                   {csvData &&
                                        csvData.map((row, index) => (
                                             <tr key={index}>
                                                  {row.map((cell, idx) => (
                                                       <td key={idx}>{cell}</td>
                                                  ))}
                                             </tr>
                                        ))}
                              </tbody>
                         </table>
                    );
               case "xlsx":
                    const fetchExcelData = async () => {
                         const response = await fetch(file);
                         const arrayBuffer = await response.arrayBuffer();
                         const workbook = XLSX.read(arrayBuffer, { type: "array" });
                         const firstSheetName = workbook.SheetNames[0];
                         const worksheet = workbook.Sheets[firstSheetName];
                         const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
                         setExcelData(jsonData);
                    };

                    if (!excelData) {
                         fetchExcelData();
                         return <div>Loading Excel data...</div>;
                    }

                    return (
                         <table>
                              <tbody>
                                   {excelData.map((row, index) => (
                                        <tr key={index}>
                                             {row.map((cell, idx) => (
                                                  <td key={idx}>{cell}</td>
                                             ))}
                                        </tr>
                                   ))}
                              </tbody>
                         </table>
                    );
               default:
                    return <div className="my-8 font-semibold text-lg text-secondaryLight1">Unsupported file format.</div>;
          }
     };

     return (
          <div className="preview-container p-4">
               <div className="flex items-center gap-3">
                    <svg
                         xmlns="http://www.w3.org/2000/svg"
                         viewBox="0 0 24 24"
                         fill="currentColor"
                         className="size-6 cursor-pointer"
                         onClick={() => setSelectedPreview(null)}
                    >
                         <path
                              fill-rule="evenodd"
                              d="M11.03 3.97a.75.75 0 0 1 0 1.06l-6.22 6.22H21a.75.75 0 0 1 0 1.5H4.81l6.22 6.22a.75.75 0 1 1-1.06 1.06l-7.5-7.5a.75.75 0 0 1 0-1.06l7.5-7.5a.75.75 0 0 1 1.06 0Z"
                              clip-rule="evenodd"
                         />
                    </svg>
                    <p className="font-semibold text-md">{selectedPreview?.name}</p>
               </div>
               <div className="flex items-start justify-center w-full h-full my-6">{renderFilePreview()}</div>
          </div>
     );
}
