import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { Spin } from "antd";
import moment from "moment";
import {
  GetServiceTaskApi,
  GetTaskWiseFileApi,
} from "../../api/request/service";
import MySegmented from "../../components/segmented/Segmented";
import SkeletonTable from "../../components/table/SkeletonTable";
import columns from "../../columns/files/viewFile";
import View from "../../assets/View";

const ViewFile = () => {
  const { fileTypeId } = useParams();
  const navigate = useNavigate();
  const [taskList, setTaskList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [segmentOptions, setSegmentOptions] = useState([]);
  const [segmentedValue, setSegmentedValue] = useState("");
  const [fileList, setFileList] = useState([]);
  const [isFileLoading, setIsFileLoading] = useState(false);

  const getServiceTaskList = async () => {
    try {
      setIsLoading(true);
      const params = { service_type_id: fileTypeId };
      const { data } = await GetServiceTaskApi(params);
      if (data.status && data?.data?.length) {
        setTaskList(data.data);
        const options = data.data.map((item) => item.name);
        setSegmentOptions(options);
        setSegmentedValue(options[0]);
      }
      setIsLoading(false);
    } catch (error) {
      if (error?.response?.data?.message)
        toast.error(error?.response?.data?.message);
      setIsLoading(false);
    }
  };

  const getFileList = async () => {
    try {
      setIsFileLoading(true);
      const task = taskList?.find((item) => item?.name === segmentedValue);
      let params = { task_id: task?.id };
      const { data } = await GetTaskWiseFileApi(params);
      if (data?.status) {
        setFileList(
          data?.data?.map((item) => {
            return {
              ...item,
              name: item?.user?.name,
              email: item?.user?.email,
              file: (
                <div className="font-bold highlight-text capitalize">
                  {item?.service_name_slug}
                </div>
              ),
              submit: item?.is_submit ? "Yes" : "No",
              startDate: item?.created_at
                ? moment(item?.created_at).format("lll")
                : "",
              status: (
                <p
                  className={`${
                    item?.status == "active" ? "text-green-500" : "text-red-600"
                  } font-bold uppercase`}
                >
                  {item?.status}
                </p>
              ),
              action: (
                <div
                  className="cursor-pointer"
                  onClick={() =>
                    navigate(`/file/${fileTypeId}/${item?.id}`, {
                      state: { client: item?.user },
                    })
                  }
                >
                  <View />
                </div>
              ),
            };
          })
        );
      }
      setIsFileLoading(false);
    } catch (error) {
      setIsFileLoading(false);
    }
  };

  useEffect(() => {
    if (fileTypeId) getServiceTaskList();
  }, [fileTypeId]);

  useEffect(() => {
    if (taskList?.length) getFileList();
  }, [segmentedValue, taskList]);

  return (
    <div className="border rounded-md bg-white">
      {isLoading ? (
        <div className="flex flex-col items-center gap-2 my-16">
          <Spin />
          <p className="text-secondaryLight1">Loading...</p>
        </div>
      ) : (
        <div className="flex flex-col gap-4">
          {taskList?.length ? (
            <>
              <MySegmented
                options={segmentOptions}
                value={segmentedValue}
                setValue={setSegmentedValue}
              />

              <div className="table-radius-0 text-light mb-4">
                <SkeletonTable
                  columns={columns}
                  data={fileList}
                  isLoading={isFileLoading}
                />
              </div>
            </>
          ) : (
            <p className="text-center text-Gray my-16">No task available</p>
          )}
        </div>
      )}
    </div>
  );
};

export default ViewFile;
