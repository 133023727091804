import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Form, Input, Spin, Switch } from "antd";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Add from "../../../assets/Add";
import LoadableButton from "../../../components/button/LoadableButton";
import ModalComponent from "../../../components/global/modals/ModalComponent";
import {
  CreateTaskApi,
  UpdateTaskApi,
  GetSubTaskApi,
  GetTagApi,
  CreateSubTaskApi,
  DeleteSubTaskApi,
  ReOrderSubTaskApi,
} from "../../../api/request/pipeline";
import Spinner from "../../../components/loader/Spinner";

const { TextArea } = Input;

const Task = ({ pipelineId, mode, task, setCategory }) => {
  const [taskForm] = Form.useForm();
  const [subTaskForm] = Form.useForm();
  const taskFormValue = task || {};
  const subTaskFormValue = { name: "", description: "" };

  const [tagData, setTagData] = useState([]);
  const [selectedTag, setSelectedTag] = useState(task?.tags || "");
  const [subTaskData, setSubTaskData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isTagLoading, setIsTagLoading] = useState(false);
  const [isTaskLoading, setIsTaskLoading] = useState(false);
  const [isSubTaskLoading, setIsSubTaskLoading] = useState(false);
  const [isSubTask, setIsSubTask] = useState(false);

  const getTagList = async () => {
    try {
      setIsTagLoading(true);
      const { data } = await GetTagApi();
      if (data.status) {
        setTagData(data?.data);
        setIsTagLoading(false);
      }
    } catch (error) {
      setIsTagLoading(false);
    }
  };

  const getSubTaskList = async () => {
    try {
      setIsLoading(true);
      const params = { pipeline_task_id: task.id };
      const { data } = await GetSubTaskApi(params);
      if (data.status) {
        setSubTaskData(data?.data);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getTagList();
  }, []);

  useEffect(() => {
    if (task?.id) getSubTaskList();
  }, [task?.id]);

  const onSubmitTask = async (values) => {
    try {
      setIsTaskLoading(true);
      let data = {};

      const params = {
        ...(mode === "edit"
          ? { id: task?.id }
          : { pipeline_temp_id: pipelineId }),
        ...values,
        tags: selectedTag,
      };

      if (mode === "edit") {
        const response = await UpdateTaskApi(params);
        data = response?.data;
      } else {
        const response = await CreateTaskApi(params);
        data = response?.data;
      }

      if (data.status) {
        setIsTaskLoading(false);
        toast.success(data.message);
        setCategory("pipeline");
      }
    } catch (error) {
      if (error?.response?.data?.message)
        toast.error(error?.response?.data?.message);
      setIsTaskLoading(false);
    }
  };

  const onAddSubTask = async (values) => {
    try {
      setIsSubTaskLoading(true);
      const params = {
        pipeline_task_id: task?.id,
        ...values,
      };
      const { data } = await CreateSubTaskApi(params);
      if (data.status) {
        setIsSubTaskLoading(false);
        subTaskForm.resetFields();
        toast.success(data.message);
        setIsSubTask(false);
        setSubTaskData([...subTaskData, data?.data]);
      }
    } catch (error) {
      if (error?.response?.data?.message)
        toast.error(error?.response?.data?.message);
      setIsSubTaskLoading(false);
    }
  };

  const onDeleteSubTask = async (id) => {
    const params = { sub_task_id: id };
    const { data } = await DeleteSubTaskApi(params);
    if (data.status)
      setSubTaskData(subTaskData.filter((item) => item.id !== id));
  };

  const onDragEnd = async (result) => {
    if (!result.destination) return;
    const newItems = Array.from(subTaskData);
    const [movedItem] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, movedItem);
    setSubTaskData(newItems);

    try {
      let params = {
        task_id: result?.draggableId,
        task_order: result?.destination?.index + 1,
      };
      await ReOrderSubTaskApi(params);
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      }
    }
  };

  return (
    <>
      <div className="grid sm:grid-cols-4 gap-3">
        <div className="sm:col-span-3 border rounded-xl bg-White w-full">
          <div className="p-4 border-b">
            <h2 className="font-semibold text-xl">
              {mode === "edit" ? "Edit Task" : "Create Task"}
            </h2>
          </div>
          <Form
            className="w-full p-4 flex flex-col gap-3"
            autoComplete="off"
            form={taskForm}
            initialValues={taskFormValue}
            onFinish={onSubmitTask}
          >
            <div>
              <label className="text-base font-medium">Name</label>
              <Form.Item
                name="name"
                rules={[{ required: true, message: "Please enter name!" }]}
                className="my-2"
              >
                <Input placeholder="Name" size="large" />
              </Form.Item>
            </div>
            <Form.Item name="description" className="my-2">
              <TextArea
                placeholder="Add text or multimedia"
                autoSize={{ minRows: 3 }}
                size="large"
              />
            </Form.Item>
            <div>
              <label className="text-base font-medium">Time Duration</label>
              <Form.Item
                name="task_duration"
                rules={[
                  { required: true, message: "Please enter time duration!" },
                ]}
                className="my-2"
              >
                <Input
                  type="number"
                  min={0}
                  placeholder="Time duration"
                  addonAfter="Hour"
                  size="large"
                />
              </Form.Item>
            </div>
            <div>
              <label className="text-base font-medium">Tag</label>
              <div className="flex flex-wrap sm:flex-row flex-col gap-3 my-2">
                {isTagLoading ? (
                  <Spinner />
                ) : (
                  <>
                    {tagData.map((item, index) => (
                      <button
                        key={`tag_${index}`}
                        type="button"
                        className={`border rounded-md px-3 py-2 font-medium ${
                          item?.id === selectedTag
                            ? "border-primary text-primary"
                            : "border-Gray text-Gray"
                        }`}
                        onClick={() => setSelectedTag(item?.id)}
                      >
                        {item?.name}
                      </button>
                    ))}
                  </>
                )}
              </div>
            </div>
            <div>
              <div className="flex items-center gap-5">
                <label className="text-base font-medium">Verify Payment</label>
                <Form.Item name="is_next_payment" className="my-2">
                  <Switch />
                </Form.Item>
              </div>
              {/* <div className="flex items-center gap-5">
                <label className="text-base font-medium">
                  Verify Signature
                </label>
                <Form.Item name="is_signature" className="my-2">
                  <Switch />
                </Form.Item>
              </div> */}
            </div>
            <div className="mt-5 flex gap-3">
              <LoadableButton
                className="bg-secondary text-sm text-white font-medium px-8 py-2 rounded-lg themeHover duration-500"
                type="submit"
                lable="Submit"
                isLoading={isTaskLoading}
                loadingLable="Submiting task..."
              />
              <button
                className="flex items-center px-4 py-1.5 font-medium border border-secondary text-secondary rounded-lg"
                onClick={() => setCategory("pipeline")}
              >
                Cancel
              </button>
            </div>
          </Form>
        </div>

        {mode === "edit" && (
          <div className="border rounded-xl bg-White p-5 ">
            <p className="mb-5">Sub Task</p>

            {isLoading ? (
              <div className="flex flex-col items-center gap-2 my-16">
                <Spin />
                <p className="text-secondaryLight1">Loading...</p>
              </div>
            ) : (
              <div>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        {subTaskData.map((item, index) => (
                          <Draggable
                            key={item.id}
                            draggableId={item.id}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className="flex items-center gap-2 mb-2 "
                              >
                                <img
                                  src="/assets/icons/move-dnd.svg"
                                  alt="icon"
                                />
                                <div className="border rounded-md px-3 py-2 w-full">
                                  {item?.name}
                                </div>
                                <button
                                  onClick={() => onDeleteSubTask(item.id)}
                                >
                                  <img
                                    src="/assets/icons/delete.svg"
                                    alt="icon"
                                  />
                                </button>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>

                <button
                  onClick={() => setIsSubTask(true)}
                  className=" text-secondary rounded-md flex items-center gap-2 mt-5"
                >
                  <Add color="#1E4387" />
                  <span>Add Sub Task</span>
                </button>
              </div>
            )}
          </div>
        )}
      </div>

      <ModalComponent
        isOpen={isSubTask}
        setIsOpen={setIsSubTask}
        title="Add Sub Task"
      >
        <Form
          className="w-full flex flex-col gap-3"
          autoComplete="off"
          form={subTaskForm}
          initialValues={subTaskFormValue}
          onFinish={onAddSubTask}
        >
          <Form.Item
            name="name"
            className="my-2"
            rules={[{ required: true, message: "Please enter sub task name!" }]}
          >
            <Input placeholder="Name" size="large" />
          </Form.Item>
          <Form.Item name="description" className="my-2">
            <TextArea
              placeholder="Add text or multimedia"
              autoSize={{ minRows: 3 }}
              size="large"
            />
          </Form.Item>
          <div className="text-center mt-2">
            <LoadableButton
              className="bg-primary text-sm text-white font-medium px-8 py-2 rounded-lg themeHover duration-500"
              type="submit"
              lable="Save"
              isLoading={isSubTaskLoading}
              loadingLable="Adding SubTask..."
            />
          </div>
        </Form>
      </ModalComponent>
    </>
  );
};

export default Task;
