import React from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const Spinner = ({ size }) => {
  return (
    <Spin
      indicator={
        <LoadingOutlined
          style={{
            fontSize: size || 24,
            color: "$theme",
          }}
          spin
        />
      }
    />
  );
};

export default Spinner;
