import React, { useState } from "react";
import { ViewClientNoteApi, SendNoteToClientApi } from "../../../api/request/clients/clients";
import { Form, Input, Spin } from "antd";
import toast from "react-hot-toast";
import LoadableButton from "../../../components/button/LoadableButton";
const { TextArea } = Input;

export default function Notes({ client }) {
     const [form] = Form.useForm();
     const [initialValues, setInitialValues] = useState({ massage: "" });
     const [isLoading, setIsLoading] = useState(false);
     const [isNoteLoading, setIsNoteLoading] = useState(false);

     const viewClientNote = async () => {
          try {
               setIsLoading(true);
               const { data } = await ViewClientNoteApi({ client_id: client?.id });
               if (data?.status) {
                    setInitialValues({ massage: data?.data?.massage });
               }
               setIsLoading(false);
          } catch (error) {
               setIsLoading(false);
          }
     };

     useState(() => {
          viewClientNote();
     }, []);

     const handleNote = async (values) => {
          try {
               setIsNoteLoading(true);
               const params = {
                    note_id: client?.id,
                    ...values,
               };
               const { data } = await SendNoteToClientApi(params);
               if (data?.status) {
                    toast.success(data?.message);
                    setInitialValues({ massage: values?.massage });
               }
               setIsNoteLoading(false);
          } catch (error) {
               if (error?.response?.data?.message) {
                    toast.error(error?.response?.data?.message);
               }
               setIsNoteLoading(false);
          }
     };

     return (
          <div className="p-4">
               {isLoading ? (
                    <div className="flex justify-center flex-col my-[10%]">
                         <Spin size="large" />
                         <p className="primary text-center mt-2">Loading...</p>
                    </div>
               ) : (
                    <Form className="w-full" autoComplete="off" form={form} initialValues={initialValues} onFinish={handleNote}>
                         <p>Client Note:</p>
                         <Form.Item name="massage" className="mb-0" rules={[{ required: true, message: "Please enter note." }]}>
                              <TextArea className="mt-1" rows={5} placeholder="Note for client..." />
                         </Form.Item>
                         <div className="flex justify-end">
                              <LoadableButton
                                   className="bg-primary text-sm text-white font-medium uppercase px-8 py-2 mt-6 rounded-lg themeHover duration-500"
                                   type="submit"
                                   lable="Save"
                                   isLoading={isNoteLoading}
                                   loadingLable="Saving..."
                              />
                         </div>
                    </Form>
               )}
          </div>
     );
}
