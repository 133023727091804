import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { Sidebar, Menu, SubMenu } from "react-pro-sidebar";
import "./Sidebar.scss";
import { sidebarData } from "./sidebarData/sidebarData.js";
import Logout from "../../../assets/Logout.jsx";
import ConfirmationModal from "../modals/ConfirmationModal.jsx";
import { setloggedIn } from "../../../store/Slices/userSlice.js";
import { removeCookies } from "../../../services/Cookies.jsx";
import { LogoutApi } from "../../../api/request/auth.js";

const AppSidebar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openSubMenuIndex, setOpenSubMenuIndex] = useState(null);

  const getFirstPath = (path) => {
    const parts = path.split("/");
    return parts.length > 1 ? "/" + parts[1] : path;
  };

  const isActivePath = (path) => {
    let active = false;
    path?.map((item) => {
      if (item == getFirstPath(pathname)) {
        active = true;
      }
    });
    return active;
  };

  const handleMenuClick = (index) => {
    setOpenSubMenuIndex(index);
  };

  const clearAll = () => {
    localStorage.clear();
    dispatch(setloggedIn(false));
    removeCookies("token");
    navigate("/login");
  };

  const logoutHandler = async () => {
    try {
      setIsLoading(true);
      const { data } = await LogoutApi();
      if (data?.status) toast.success(data?.message);
      clearAll();
      setIsLoading(false);
    } catch (error) {
      if (error?.response?.data?.message)
        toast.error(error?.response?.data?.message);
      clearAll();
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="sidebar-container w-[250px] fixed border-r">
        <div className="border-b p-4">
          <div>
            <img
              src="/assets/icons/logo.svg"
              alt="logo"
              className="h-10 w-full object-contain"
            />
          </div>
        </div>

        <div className="h-[calc(100vh-158px)] overflow-y-auto pr-4 my-3">
          {sidebarData.map((item, index) => {
            const iconWithProps = item?.icon
              ? React.cloneElement(item?.icon, {
                  active: isActivePath(item?.activeUrl),
                })
              : null;
            return item?.children ? (
              <Sidebar>
                <Menu>
                  <SubMenu
                    key={index}
                    open={openSubMenuIndex == index}
                    onOpenChange={() =>
                      handleMenuClick(index == openSubMenuIndex ? null : index)
                    }
                    label={
                      <div
                        key={index}
                        className={`flex items-center font-medium cursor-pointer py-2 ps-9 header`}
                      >
                        {item?.icon}
                        <p className="text-base ms-3">{item?.title}</p>
                      </div>
                    }
                  >
                    <div className="pt-1">
                      {item?.children?.map((childItem, index) => {
                        const childIconWithProps = React.cloneElement(
                          childItem?.icon,
                          { active: isActivePath(childItem?.activeUrl) }
                        );
                        return (
                          <div className="flex items-center gap-3 w-full mt-3">
                            <div
                              className={`w-1 h-10 rounded-e-md duration-200 ${
                                isActivePath(childItem?.activeUrl)
                                  ? "bg-primary"
                                  : null
                              }`}
                            ></div>
                            <div
                              key={index}
                              onClick={() => navigate(childItem?.url)}
                              className={`submenu child flex items-center cursor-pointer font-medium py-2 ps-10 mr-4 duration-200 w-full ${
                                isActivePath(item?.activeUrl)
                                  ? "text-White bg-primary rounded-lg font-semibold"
                                  : null
                              }`}
                            >
                              {childIconWithProps}
                              <p className="ms-2">{childItem?.title}</p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </SubMenu>
                </Menu>
              </Sidebar>
            ) : (
              <div
                key={index}
                className="flex items-center gap-3 my-3 mb-5 ps-4"
              >
                <div
                  onClick={() => navigate(item?.url)}
                  className={`font-medium cursor-pointer duration-200 w-full ${
                    isActivePath(item?.activeUrl)
                      ? " text-White bg-primary rounded-lg font-semibold"
                      : null
                  }`}
                >
                  <button className="flex items-center w-full py-2 px-6">
                    {iconWithProps}
                    <p className="text-base ms-3">{item?.title}</p>
                  </button>
                </div>
              </div>
            );
          })}
        </div>

        <div className="px-4 pb-5">
          <button
            className="flex items-center justify-center gap-3 bg-secondary text-white uppercase text-base font-semibold w-full py-2 rounded-lg"
            onClick={() => setIsModalOpen(true)}
          >
            <Logout />
            <p>Logout</p>
          </button>
        </div>
      </div>

      {/* --- Loguot confirmation modal --- */}
      <ConfirmationModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        message="Are you sure you want to logout?"
        onConfirm={logoutHandler}
        isLoading={isLoading}
        loadingLabel="Loging out..."
      />
    </>
  );
};

export default AppSidebar;
