import React, { useEffect, useState } from "react";
import ColumnBarChart from "../../components/chart/ColumnBarChart";
import { Spin } from "antd";
import { GetAdminDashboardApi } from "../../api/request/dashboard/dashboard";
import moment from "moment/moment";
import AreaChart from "../../components/chart/AreaChart";

const Dashboard = () => {
  const [isLoading, setIsLoading] = useState(false);
  //  const [data, setData] = useState(null);
  const [taskData, setTaskData] = useState({
    categories: [],
    series: [],
  });

  const [allData, setAllData] = useState([]);
  console.log(taskData, "=====taskData");

  const [dashboardCards, setDashboardCards] = useState([
    {
      title: "Active job by files",
      total: "10",
      icon: "/assets/icons/active-job-files.svg",
      bgColor: "bg-[#E5FAFF]",
    },
    {
      title: "Completed files",
      total: "30",
      icon: "/assets/icons/completed-files.svg",
      bgColor: "bg-[#DDF6E8]",
    },
    {
      title: "Overdue files",
      total: "20",
      icon: "/assets/icons/overdue-files.svg",
      bgColor: "bg-[#cdd8ec]",
    },
    {
      title: "Pending tickets",
      total: "20",
      icon: "/assets/icons/pending-tickets.svg",
      bgColor: "bg-[#cfcced]",
    },
  ]);
  const totalClientData = { categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'], series: [{ name: 'Client', data: [12, 10, 28, 8, 19, 34, 49] }] };

  const getDashboard = async () => {
    try {
      setIsLoading(true);
      const { data } = await GetAdminDashboardApi();
      setAllData(data);
      if (data?.status) {
        const updatedTotals = [data?.data?.total_clients, data?.data?.completed_files, data?.data?.overdue_task, data?.data?.pending_files];
        setDashboardCards(dashboardCards.map((card, index) => ({ ...card, total: updatedTotals[index] })));
        const chartData = data.data.chart_data || [];
        const taskData = {
          categories: Array.isArray(chartData)
            ? chartData.map((item) => moment(item.date).format("MMM DD"))
            : [],
          series: [
            {
              name: "Completed",
              data: Array.isArray(chartData)
                ? chartData.map((item) => item.completed_tasks || 0)
                : [],
            },
            {
              name: "Pending",
              data: Array.isArray(chartData)
                ? chartData.map((item) => item.running_tasks || 0)
                : [],
            },
          ],
        };
        setTaskData(taskData);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getDashboard();
  }, []);
  return (
    <>
      {isLoading ? (
        <div className="flex justify-center flex-col mt-[20%]">
          <Spin size="large" />
          <p className="primary text-center mt-2">Loading...</p>
        </div>
      ) : (
        <div>
          <div className="grid sm:grid-cols-4 gap-3">
            {dashboardCards.map((item, index) => (
              <div key={index} className="border rounded-xl p-4 bg-white">
                <div className="flex justify-between items-center">
                  <div>
                    <p className="font-medium">{item.title}</p>
                    <p className="font-semibold text-2xl mt-2">{item.total}</p>
                  </div>
                  <div
                    className={`${item.bgColor} rounded-xl size-14 flex items-center justify-center`}
                  >
                    <img src={item.icon} alt="icon" />
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="grid xl:grid-cols-3 gap-5 mt-5">
            <div className="xl:col-span-2 border-2 rounded-xl bg-white">
              <div className="flex items-center justify-end gap-3 text-sm px-10">
                <p>
                  <span className="text-xl text-secondary">●</span> Complete
                </p>
                <p>
                  <span className="text-xl text-Gray ">●</span> Pending
                </p>
              </div>
              <div>
                <ColumnBarChart
                  categories={taskData.categories}
                  series={taskData.series}
                />
              </div>
            </div>

            <div className="grid sm:grid-cols-2 gap-5">
              <div className="bg-white border rounded-xl ">
                <div className="px-4 pt-4">
                  <h4 className="text-Gray text-lg">Total Client</h4>
                  <h2 className="text-2xl font-bold">
                    {allData?.data?.total_clients}
                  </h2>
                </div>
                <div className="">
                  {/* <img src="/assets/images/total-client.png" alt="image" /> */}
                  <AreaChart data={totalClientData} colors={['#7367F0']} height={124} />
                </div>
              </div>
              <div className="bg-white border rounded-xl flex items-center justify-center gap-3">
                <div className="bg-[#cdd8ec] size-12 rounded-lg flex items-center justify-center">
                  <img src="/assets/icons/files-secondary.svg" alt="icon" />
                </div>
                <div>
                  <h4 className="text-Gray text-lg">Files</h4>
                  <h2 className="text-2xl font-bold">
                    {allData?.data?.all_files}
                  </h2>
                </div>
              </div>
              <div className="bg-white border rounded-xl  flex items-center justify-center gap-3">
                <div className="bg-[#DDF6E8] size-12 rounded-lg flex items-center justify-center">
                  <img src="/assets/icons/invoice.svg" alt="icon" />
                </div>
                <div>
                  <h4 className="text-Gray text-lg">Invoice</h4>
                  <h2 className="text-2xl font-bold">
                    {allData?.data?.invoice_generated_files}
                  </h2>
                </div>
              </div>
              <div className="bg-white border rounded-xl  ">
                <div className="px-4 pt-4">
                  <h4 className="text-Gray text-lg">Signature</h4>
                  <h2 className="text-2xl font-bold">{allData?.data?.signature}</h2>
                </div>
                <div className="">
                  <img src="/assets/images/signature.png" alt="image" />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Dashboard;
