import React, { useState } from "react";
import toast from "react-hot-toast";
import { Input, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { AddUpdateQuestionApi } from "../../../api/request/service.js";

const MyFileUpload = ({ question }) => {
  const [value, setValue] = useState(question?.field_label);
  const [isCompleted, setIsCompleted] = useState(value?.length ? true : false);
  const [isLoading, setIsLoading] = useState(false);

  const onChange = (event) => {
    setValue(event?.target?.value);
    setIsCompleted(false);
  };

  const handleSubmit = async () => {
    if (!value) return;
    try {
      setIsLoading(true);
      let params = {
        question_id: question?.id,
        section_id: question?.service_section_id,
        field_label: value,
      };
      const { data } = await AddUpdateQuestionApi(params);
      if (data?.status) setIsCompleted(true);
      setIsLoading(false);
    } catch (error) {
      if (error?.response?.data?.message)
        toast.error(error?.response?.data?.message);
      setIsLoading(false);
    }
  };

  return (
    <div className="flex items-center gap-4 my-2">
      <img
        src="/assets/icons/double-ellipsis-vertical.svg"
        className="cursor-grab"
        alt="icon"
      />

      <Input
        className="py-3 ps-4"
        size="large"
        placeholder="File Upload"
        onChange={onChange}
        value={value}
        onBlur={handleSubmit}
      />

      <div className="h-[22px] w-[22px]">
        {isLoading ? (
          <Spin indicator={<LoadingOutlined spin />} className="size-[22px]" />
        ) : (
          <div className="size-[22px]">
            {isCompleted ? (
              <img
                className="size-6"
                src="/assets/icons/check-circle.svg"
                alt="icon"
              />
            ) : (
              <img
                className="size-[22px]"
                src="/assets/icons/x-circle.svg"
                alt="icon"
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default MyFileUpload;
