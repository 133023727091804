import api from "../../index";
export const GetClientByIdApi = (data) => api.post("clientinfo", data);

export const GetClientDocumentsApi = (data) => api.post("filefolderlist", data);
export const DownloadFileFolderApi = (data) => api.post("download-file-folder", data);
export const GetChildFolderListApi = (data) => api.post("viewfilefolder", data);
export const DeleteFileFolderApi = (data) => api.post("deletefilefolder", data);
export const UploadFileFolderApi = (data, headers) => api.post("uploadfilefolder", data, headers);
export const MoveFileFolderApi = (data) => api.post("movefilefolder", data);

// Communication
export const GetOrganizerListApi = (data) => api.post("clientorganizerlist", data);
export const CreateChatApi = (data) => api.post("chatcreate", data);
export const GetActiveChatApi = (data) => api.post("chatroomsbydate", data);
export const GetArchiveChatApi = (data) => api.post("archivechatlist", data);
export const GetMessageListApi = (data) => api.post("chatmessageslist", data);

// Notes
export const ViewClientNoteApi = (data) => api.post("notelist", data);
export const SendNoteToClientApi = (data) => api.post("addupdatenotes", data);

// File
export const GetClientFilesApi = (data) => api.post("clientfilelist", data);
