import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { Form, Image, Spin, Upload } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import SkeletonTable from "../../components/table/SkeletonTable";
import columns from "../../columns/files/reviewFile";
import {
  CompleteTaskApi,
  GetFileAnswerApi,
  GetFileDetailsApi,
  ReviewAnswerApi,
  SendInvoiceApi,
  SendToSignApi,
  UnsubmitFileApi,
} from "../../api/request/service";
import ConfirmationModal from "../../components/global/modals/ConfirmationModal";
import ModalComponent from "../../components/modals/ModalComponent";
import LoadableButton from "../../components/button/LoadableButton";

const ReviewFile = () => {
  const { fileTypeId, id } = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [fileData, setFileData] = useState(null);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isUnsubmitOpen, setIsUnsubmitOpen] = useState(false);
  const [isUnsubmitLoading, setIsUnsubmitLoading] = useState(false);
  const [isCompleteTaskLoading, setIsCompleteTaskLoading] = useState(false);
  const [isSendInvoiceLoading, setIsSendInvoiceLoading] = useState(false);

  const [uploadFileForm] = Form.useForm();
  const [isOpenUploadModal, setIsOpenUploadModal] = useState(false);
  const [isUploadFileLoading, setIsUploadFileLoading] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [fileList, setFileList] = useState([]);

  const getAnswer = async () => {
    try {
      setIsLoading(true);
      let params = { file_id: id };
      const { data } = await GetFileAnswerApi(params);
      if (data?.status) {
        setData(
          data?.data?.map((section) => {
            return {
              ...section,
              questions: section?.questions?.map((question) => {
                return {
                  ...question,
                  ...(question?.document?.path && {
                    response_text: (
                      <a
                        href={question?.document?.path}
                        className="hover:text-primary text-primary underline underline-offset-4"
                        target="_blank"
                      >
                        View File🔻
                      </a>
                    ),
                  }),
                  question: (
                    <div
                      className={`${
                        !question?.reviewed_checked
                          ? "font-semibold highlight-text"
                          : ""
                      }`}
                    >
                      {question?.field_label || "--"}
                    </div>
                  ),
                  review: (
                    <div
                      className="cursor-pointer"
                      onClick={() =>
                        reviewHandler(
                          section?.id,
                          question?.id,
                          question?.reviewed_checked
                        )
                      }
                    >
                      <img
                        className="size-7"
                        src={
                          question?.reviewed_checked
                            ? "/assets/icons/outlined-check-circle.svg"
                            : "/assets/icons/outlined-circle.svg"
                        }
                      />
                    </div>
                  ),
                };
              }),
            };
          })
        );
      }
      setIsLoading(false);
    } catch (error) {
      if (error?.response?.data?.message)
        toast.error(error?.response?.data?.message);
      setIsLoading(false);
    }
  };

  const getFileDetails = async () => {
    try {
      setIsLoading(true);
      let params = { file_id: id };
      const { data } = await GetFileDetailsApi(params);
      if (data?.status) setFileData(data?.data);
      setIsLoading(false);
    } catch (error) {
      if (error?.response?.data?.message)
        toast.error(error?.response?.data?.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      getFileDetails();
      getAnswer();
    }
  }, [id]);

  useEffect(() => {
    setFileList([]);
    uploadFileForm.resetFields();
  }, [isOpenUploadModal]);

  const spinner = (
    <div className="my-[3px]">
      <Spin indicator={<LoadingOutlined />} />
    </div>
  );

  const setReviewData = (sectionId, questionId, status, isLoading) => {
    setData((prev) => {
      return prev.map((section) => {
        if (section.id === sectionId) {
          return {
            ...section,
            questions: section?.questions?.map((question) => {
              if (question.id === questionId) {
                return {
                  ...question,
                  reviewed_checked: status,
                  question: (
                    <div
                      className={`${
                        !status ? "font-semibold highlight-text" : ""
                      }`}
                    >
                      {question?.field_label || "--"}
                    </div>
                  ),
                  review: isLoading ? (
                    spinner
                  ) : (
                    <div
                      className="cursor-pointer"
                      onClick={() =>
                        reviewHandler(sectionId, questionId, status)
                      }
                    >
                      <img
                        className="size-7"
                        src={
                          status
                            ? "/assets/icons/outlined-check-circle.svg"
                            : "/assets/icons/outlined-circle.svg"
                        }
                      />
                    </div>
                  ),
                };
              }
              return question;
            }),
          };
        }
        return section;
      });
    });
  };

  const reviewHandler = async (sectionId, questionId, status) => {
    try {
      setReviewData(sectionId, questionId, !status, true);

      let params = {
        id: questionId,
        reviewed_checked: !status,
      };
      const { data } = await ReviewAnswerApi(params);
      if (data?.status) setReviewData(sectionId, questionId, !status, false);
    } catch (error) {
      if (error?.response?.data?.message)
        toast.error(error?.response?.data?.message);
      setReviewData(sectionId, questionId, status, false);
    }
  };

  const completeTask = async () => {
    try {
      setIsCompleteTaskLoading(true);
      const { data } = await CompleteTaskApi({ file_id: id });
      if (data?.status) {
        toast.success(data?.message);
        navigate(`/file/${fileTypeId}`);
      }
      setIsCompleteTaskLoading(false);
    } catch (error) {
      if (error?.response?.data?.message)
        toast.error(error?.response?.data?.message);
      setIsCompleteTaskLoading(false);
    }
  };

  const unsubmitFile = async () => {
    if (fileData?.unsubmit === 1) return;
    try {
      setIsUnsubmitLoading(true);
      const { data } = await UnsubmitFileApi({ file_id: id });
      if (data?.status) {
        toast.success(data?.message);
        setIsUnsubmitOpen(false);
        setFileData({ ...fileData, unsubmit: 1 });
      }
      setIsUnsubmitLoading(false);
    } catch (error) {
      if (error?.response?.data?.message)
        toast.error(error?.response?.data?.message);
      setIsUnsubmitLoading(false);
    }
  };

  const sendInvoice = async () => {
    if (fileData?.is_invoice_send === 1) return;
    try {
      setIsSendInvoiceLoading(true);
      const { data } = await SendInvoiceApi({ file_id: id });
      if (data?.status) {
        toast.success(data?.message);
        setFileData({ ...fileData, is_invoice_send: 1 });
      }
      setIsSendInvoiceLoading(false);
    } catch (error) {
      if (error?.response?.data?.message)
        toast.error(error?.response?.data?.message);
      setIsSendInvoiceLoading(false);
    }
  };

  const sendToSign = async (values) => {
    try {
      setIsUploadFileLoading(true);
      const params = {
        client_id: state?.client?.id,
        file: values?.files[0]?.originFileObj,
      };
      const apiHeader = {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      };
      const { data } = await SendToSignApi(params, apiHeader);
      if (data?.status) {
        toast.success(data?.message);
        uploadFileForm.resetFields();
        setIsOpenUploadModal(false);
      }
      setIsUploadFileLoading(false);
    } catch (error) {
      if (error?.response?.data?.message)
        toast.error(error?.response?.data?.message);
      setIsUploadFileLoading(false);
    }
  };

  const handleChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    uploadFileForm.setFieldsValue({ files: newFileList });
    if (newFileList?.length == 0) {
      uploadFileForm.setFieldsValue({ files: [] });
    }
  };

  return (
    <>
      <div className="bg-white border rounded-xl p-5">
        <div className="flex justify-between gap-5 mb-5">
          <p className="font-semibold text-xl">Review File</p>

          {fileData && (
            <div className="flex items-center gap-3">
              {fileData?.invoice_pdf ? (
                <a
                  href={fileData?.invoice_pdf}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={`font-semibold underline text-primary`}
                >
                  View Invoice
                </a>
              ) : (
                <LoadableButton
                  className="bg-secondary text-sm text-white font-medium px-4 py-2 uppercase rounded-lg"
                  type="submit"
                  lable={
                    fileData?.is_invoice_send === 1
                      ? "Invoice Sended"
                      : "Send Invoice"
                  }
                  onClick={sendInvoice}
                  isLoading={isSendInvoiceLoading}
                  loadingLable="Sending Invoice..."
                  disabled={fileData?.is_invoice_send === 1}
                />
              )}

              <LoadableButton
                className="bg-secondary text-sm text-white font-medium px-4 py-2 uppercase rounded-lg"
                lable={"Send to Sign"}
                onClick={() => setIsOpenUploadModal(true)}
              />

              <LoadableButton
                className="bg-secondary text-sm text-white font-medium px-4 py-2 uppercase rounded-lg"
                lable={fileData?.unsubmit === 1 ? "Unsubmitted" : "Unsubmit"}
                onClick={() => setIsUnsubmitOpen(true)}
                disabled={fileData?.unsubmit === 1}
              />
            </div>
          )}
        </div>

        {isLoading ? (
          <div className="flex justify-center flex-col mt-[20%]">
            <Spin size="large" />
            <p className="primary text-center mt-2">Loading...</p>
          </div>
        ) : (
          <>
            {data?.length ? (
              <>
                {data?.map((item, index) => (
                  <div className="table-radius-0 text-light mb-10">
                    <p className="font-semibold text-xl text-primary mb-5">
                      {`Section ${index + 1}:`} {item?.section_name}
                    </p>
                    <SkeletonTable
                      columns={columns}
                      data={item?.questions}
                      isLoading={isLoading}
                    />
                  </div>
                ))}

                <LoadableButton
                  className="bg-secondary text-sm text-white font-medium px-8 py-2 uppercase rounded-lg"
                  type="submit"
                  lable="Complete Task"
                  onClick={completeTask}
                  isLoading={isCompleteTaskLoading}
                  loadingLable="Completing Task..."
                />
              </>
            ) : null}
          </>
        )}
      </div>

      {/* Unsubmit Organizer */}
      <ConfirmationModal
        isOpen={isUnsubmitOpen}
        setIsOpen={setIsUnsubmitOpen}
        message="Are you sure, want to unsubmit this File?"
        onConfirm={unsubmitFile}
        isLoading={isUnsubmitLoading}
        loadingLabel="Unsubmiting..."
      />

      {/* --- UPload file modal --- */}
      <ModalComponent
        isOpen={isOpenUploadModal}
        setIsOpen={setIsOpenUploadModal}
        title="Upload File"
        width={600}
      >
        <Form
          className="w-full"
          autoComplete="off"
          form={uploadFileForm}
          initialValues={{ files: [] }}
          onFinish={sendToSign}
        >
          <div>
            <Form.Item
              name="files"
              rules={[{ required: true, message: "Please upload file." }]}
            >
              <Upload
                beforeUpload={() => false}
                listType="picture-card"
                fileList={fileList}
                onChange={handleChange}
              >
                {fileList.length >= 1 ? null : (
                  <button
                    type="button"
                    className="flex flex-col items-center gap-2"
                  >
                    <div className="size-7">
                      <img src="/assets/icons/upload.svg" alt="icon" />
                    </div>
                    Upload
                  </button>
                )}
              </Upload>
            </Form.Item>
            {previewImage && (
              <Image
                wrapperStyle={{ display: "none" }}
                preview={{
                  visible: previewOpen,
                  onVisibleChange: (visible) => setPreviewOpen(visible),
                  afterOpenChange: (visible) => !visible && setPreviewImage(""),
                }}
                src={previewImage}
              />
            )}
          </div>
          <LoadableButton
            className="bg-secondary text-sm text-white font-medium px-8 py-2 mt-4 rounded-lg"
            type="submit"
            lable="Upload"
            isLoading={isUploadFileLoading}
            loadingLable="Uploading file..."
          />
        </Form>
      </ModalComponent>
    </>
  );
};

export default ReviewFile;
