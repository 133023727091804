import React, { useEffect, useState } from "react";
import { Breadcrumb, Spin } from "antd";
import Information from "./Information";
import { useNavigate, useParams } from "react-router-dom";
import Documents from "./Documents";
import Communication from "./Communication";
import Notes from "./Notes";
import Files from "./Files";
import { GetClientByIdApi } from "../../../api/request/clients/clients";

const ClientDetails = () => {
     const navigate = useNavigate();
     const { id } = useParams();
     const [client, setClient] = useState(null);
     const [isLoading, setIsLoading] = useState(false);
     const [activeTab, setActiveTab] = useState({ id: "2", title: "Information" });
     const [breadcrumbItems, setBreadcrumbItems] = useState([
          {
               title: (
                    <p className="cursor-pointer font-semibold text-black" onClick={() => navigate("/clients")}>
                         Clients
                    </p>
               ),
          },
     ]);

     const tabs = [
          { id: "2", title: "Information" },
          { id: "3", title: "Documents" },
          // { id: "4", title: "Communication" },
          { id: "5", title: "Files" },
          // { id: "6", title: "Invoice" },
          { id: "8", title: "Notes" },
          // { id: "9", title: "Referral" },
     ];

     const getClientDetails = async () => {
          if (!id) return;
          try {
               setIsLoading(true);
               let params = { id: id };
               const { data } = await GetClientByIdApi(params);
               if (data?.status) {
                    setClient(data?.data);
                    setBreadcrumbItems([
                         ...breadcrumbItems,
                         { title: <p className="capitalize">{data?.data?.name}</p> },
                         { title: <p className="capitalize">{activeTab?.title}</p> },
                    ]);
               }
               setIsLoading(false);
          } catch (error) {
               setIsLoading(false);
          }
     };

     useEffect(() => {
          getClientDetails();
     }, [id]);

     const changeTab = (tab) => {
          setActiveTab(tab);
          setBreadcrumbItems((current) => [...current?.slice(0, 2), { title: <p className="capitalize">{tab?.title}</p> }]);
     };

     const renderTab = () => {
          switch (activeTab?.id) {
               case "2":
                    return <Information client={client} />;
               case "3":
                    return <Documents id={id} tab={activeTab} />;
               // case "4":
               //      return <Communication client={client} />;
               case "5":
                    return <Files id={id} tab={activeTab} />;
               //  case "6":
               //       return <Invoice />;
               case "8":
                    return <Notes client={client} />;
               //  case "9":
               //       return <Referral id={id} />;
               default:
                    return <div className="flex items-center justify-center h-full text-secondaryLight1">Select a tab</div>;
          }
     };

     return (
          <div>
               {isLoading ? (
                    <div className="flex flex-col items-center gap-2 mt-48">
                         <Spin />
                         <p className="text-secondaryLight1">Loading...</p>
                    </div>
               ) : (
                    <>
                         <Breadcrumb items={breadcrumbItems} />

                         <div className="grid md:grid-cols-4 gap-4 mt-4">
                              <div className="border rounded-lg bg-white">
                                   <div className="border-b p-3 rounded-t-lg">
                                        <p className="font-semibold uppercase">List</p>
                                   </div>
                                   <div className="relative h-[calc(100vh-194px)] overflow-y-auto">
                                        {tabs?.map((tab, index) => (
                                             <div
                                                  key={index}
                                                  onClick={() => changeTab(tab)}
                                                  className={`cursor-pointer my-3 py-2 font-medium px-3 duration-300 ${
                                                       activeTab?.id == tab?.id ? "bg-secondaryLight text-primary font-semibold" : ""
                                                  }`}
                                             >
                                                  {tab?.title}
                                             </div>
                                        ))}
                                   </div>
                              </div>

                              <div className="md:col-span-3 bg-white border rounded-lg relative">{renderTab()}</div>
                         </div>
                    </>
               )}
          </div>
     );
};

export default ClientDetails;
