import React, { useEffect, useState } from "react";
import { GetClientFilesApi } from "../../../api/request/clients/clients";
import { Spin } from "antd";
import moment from "moment";
import RadialBarChart from "../../../components/chart/RadialBarChart";

export default function Files({ id, tab }) {
     const [isLoading, setIsLoading] = useState(false);
     const primaryColor = getComputedStyle(document.documentElement).getPropertyValue("--primary")?.trim();
     const [fileType, setFileType] = useState("status_active");
     const [files, setFiles] = useState([]);

     const getFileList = async () => {
          if (!tab === "5") return;
          try {
               setIsLoading(true);
               let params = {
                    client_id: id,
                    type: fileType,
               };
               const { data } = await GetClientFilesApi(params);
               if (data?.status) setFiles(data?.data);
               setIsLoading(false);
          } catch (error) {
               setFiles([]);
               setIsLoading(false);
          }
     };

     useEffect(() => {
          getFileList();
     }, [tab, fileType]);

     return (
          <div>
               <div className="border-b rounded-t-md flex justify-around uppercase font-semibold py-3">
                    <p
                         className={`${fileType === "status_active" ? "text-primary" : ""} cursor-pointer duration-300`}
                         onClick={() => setFileType("status_active")}
                    >
                         Active Files
                    </p>
                    <p
                         className={`${fileType === "is_archive" ? "text-primary" : ""} cursor-pointer duration-300`}
                         onClick={() => setFileType("is_archive")}
                    >
                         Archive Files
                    </p>
               </div>

               <div>
                    {isLoading ? (
                         <div className="flex justify-center flex-col my-[10%]">
                              <Spin size="large" />
                              <p className="primary text-center mt-2">Loading...</p>
                         </div>
                    ) : (
                         <div>
                              {files?.length > 0 ? (
                                   <div className="grid md:grid-cols-2 gap-5 p-4">
                                        {files?.map((item, index) => (
                                             <div className="border rounded-2xl py-4 ps-4">
                                                  <p className="font-semibold text-lg capitalize">{item?.service_name_slug}</p>
                                                  <div key={index} className="flex items-center justify-between cursor-pointer">
                                                       <div>
                                                            <p className="font-medium mt-3">
                                                                 <span className="text-secondaryLight1">Last Updated : </span>
                                                                 {moment(item?.updated_at).format("ll")}
                                                            </p>
                                                            <div className="flex items-center gap-2 mt-3">
                                                                 <div className="size-3 rounded-full bg-primary"></div>
                                                                 <p className="font-medium">Seal</p>
                                                            </div>
                                                            <button className="rounded-full bg-primary text-white text-sm font-semibold uppercase py-1 px-6 mt-3">
                                                                 Pending
                                                            </button>
                                                       </div>
                                                       <div>
                                                            <RadialBarChart
                                                                 values={[45]}
                                                                 labels={["Completed"]}
                                                                 colors={[primaryColor]}
                                                                 value={true}
                                                                 height={150}
                                                            />
                                                       </div>
                                                  </div>
                                             </div>
                                        ))}
                                   </div>
                              ) : (
                                   <p className="flex justify-center my-20 text-secondaryLight1">No files found</p>
                              )}
                         </div>
                    )}
               </div>
          </div>
     );
}
