import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import api from "../../api/index.js";
import { useDispatch } from "react-redux";
import { setloggedIn } from "../../store/Slices/userSlice.js";
import { cleareCookies } from "../../services/Cookies.jsx";

const SessionManager = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  api.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        dispatch(setloggedIn(false));
        cleareCookies();
        toast.error(
          "Your session has expired. Please login to pick up where you left off."
        );
        return navigate("/login");
      }
      return Promise.reject(error);
    }
  );
};

export default SessionManager;
