import React, { useState } from "react";
import Section from "./components/Section";
import Questions from "./components/Questions";

const ViewService = () => {
  const [selectedSection, setSelectedSection] = useState(null);

  return (
    <div className="grid xl:grid-cols-4 gap-4">
      <Section
        selectedSection={selectedSection}
        setSelectedSection={setSelectedSection}
      />

      <div className="xl:col-span-3">
        <Questions
          selectedSection={selectedSection}
          setSelectedSection={setSelectedSection}
        />
      </div>
    </div>
  );
};

export default ViewService;
