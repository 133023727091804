const defaultRender = (value) => (value ? value : '--');

const columns = [
    {
        title: 'Question',
        dataIndex: 'question',
        render: defaultRender,
    },
    {
        title: 'Answer',
        dataIndex: 'response_text',
        render: defaultRender,
        width: 250,
        fixed: 'right',
    },
    {
        title:'Review',
        dataIndex: 'review',
        width: 100,
        fixed: 'right',
    },
];

export default columns;