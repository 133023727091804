import { Form, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { removeCookies, setCookies } from '../../services/Cookies';
import { ResetPasswordApi } from '../../api/request/auth';
import toast from 'react-hot-toast';
import LoadableButton from '../../components/button/LoadableButton';

const ResetPassword = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const { token } = useParams();

    const [initialValues, setInitialValues] = useState({ new_password: '', confirm_password: '' });
    const [isLoading, setIsLoading] = useState(false);


    const handleSubmit = async (values) => {
        try {
            setIsLoading(true);
            delete values?.confirm_password;
            const { data } = await ResetPasswordApi(values);
            if (data?.status) {
                form.resetFields();
                removeCookies('passwordToken');
                toast.success(data?.message);
                navigate('/login');
            }
            setIsLoading(false);
        } catch (error) {
            if (error?.response?.data?.message) {
                toast.error(error?.response?.data?.message);
            }
            setIsLoading(false);
        }
    }

    useEffect(() => {
        setCookies('passwordToken', token);
    }, [token]);

    return (
        <>
            <div className='lg:w-1/2 md:w-[80%] w-full sm:p-0 p-4'>
                <Form layout='vertical' autoComplete='off' form={form} initialValues={initialValues} onFinish={handleSubmit} className='space-y-7'>
                    <div className='text-center'>
                        <p className='font-bold sm:text-3xl text-2xl mb-3'>Change password</p>
                        <p className="text-sm text-Gray">Your previous password has been reseted. Please set a new password for your account.</p>
                    </div>
                    <Form.Item
                        label={<p className='font-semibold'>New password</p>}
                        hasFeedback
                        name="new_password"
                        rules={[
                            { required: true, message: 'Please input your new password!', },
                            {
                                pattern: /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/,
                                message: 'Please enter strong password.',
                            },
                        ]}
                    >
                        <Input.Password placeholder='New password' size='large' />
                    </Form.Item>
                    <Form.Item
                        label={<p className='font-semibold'>Confirm password</p>}
                        hasFeedback
                        name="confirm_password"
                        dependencies={['new_password']}
                        rules={[
                            { required: true, message: 'Please input your  confirm password!', },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('new_password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('The new password that you entered do not match!'));
                                },
                            }),
                        ]}
                    >
                        <Input.Password placeholder='Confirm password' size='large' />
                    </Form.Item>
                    <LoadableButton
                        isLoading={isLoading}
                        type='submit'
                        lable='Reset Password'
                        loadingLable='Upadating...'
                        img='assets/icons/ArrowRight.svg'
                        className='bg-primary text-white text-base w-full py-2 rounded-md  flex justify-center items-center'
                    />
                </Form>
            </div>
        </>
    )
}

export default ResetPassword