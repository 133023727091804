import React, { useEffect, useState } from 'react';
import { Checkbox, Form, Input } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import LoadableButton from '../../components/button/LoadableButton';
import { CheckLoginUrlApi, LoginApi } from '../../api/request/auth';
import toast from 'react-hot-toast';
import { setCookies } from '../../services/Cookies';

const Login = () => {
    const navigate = useNavigate();
    const [form] = Form.useForm();

    const [initialValues, setInitialValues] = useState({ email: '', password: '' });
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (values) => {
        const params = {
            ...values,
            login_type: 'api',
            user_type: 'admin',
        }
        try {
            setIsLoading(true);
            const { data } = await LoginApi(params);
            if (data?.status) {
                form.resetFields();
                localStorage.setItem('email', values?.email);
                toast.success(data?.message);
                navigate('/otp');
            }
            setIsLoading(false);
        }
        catch (error) {
            if (error?.response?.data?.message) {
                toast.error(error?.response?.data?.message);
            }
            setIsLoading(false);
        }
    }

    const checkLoginUrl = async () => {
        try {
            const { data } = await CheckLoginUrlApi({ domain_name: 'example.com' });
            if (data?.status) {
                setCookies('token', data?.token);
            }
        }
        catch (error) {
        }
    }

    useEffect(() => {
        checkLoginUrl();
    }, []);

    return (
        <>
            <div className='lg:w-3/5 md:w-[80%] w-full sm:p-0 p-4'>
                <Form layout='vertical' autoComplete='off' form={form} onFinish={handleSubmit} initialValues={initialValues} className='space-y-7'>
                    <p className="sm:text-3xl text-2xl text-center font-bold">Login to <span className='text-primary'>Trackmydoc</span></p>
                    <Form.Item
                        label={<p className='font-semibold'>Email</p>}
                        name="email"
                        rules={[
                            { required: true, message: 'Please input your email!', },
                            { type: 'email', message: 'Please input valid email!', },
                        ]}
                    >
                        <Input placeholder='Email address' size='large' />
                    </Form.Item>
                    <Form.Item
                        label={<div className='flex'><p className='font-semibold'>Password</p></div>}
                        name="password"
                        rules={[{ required: true, message: 'Please input your password!', },]}
                    >
                        <Input.Password placeholder='Password' size='large' className='col-span-2' />
                    </Form.Item>
                    <div style={{ marginTop: '4px' }} className='text-primary cursor-pointer text-right'><Link to='/forget_password' >Forgot Password</Link></div>

                    <LoadableButton
                        type='submit'
                        isLoading={isLoading}
                        lable='Login'
                        loadingLable='Verifying...'
                        img='assets/icons/ArrowRight.svg'
                        className='bg-primary text-white text-base w-full py-2 rounded-md mb-5 flex justify-center items-center'
                    />
                </Form>
            </div >
        </>
    )
}

export default Login