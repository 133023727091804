import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { Form, Input, Select } from "antd";
import SkeletonTable from "../../components/table/SkeletonTable";
import columns from "../../columns/services/column";
import ModalComponent from "../../components/global/modals/ModalComponent";
import LoadableButton from "../../components/button/LoadableButton";
import View from "../../assets/View";
import { CreateServiceApi, getAllServiceApi } from "../../api/request/service";
import { getAllPipelineApi } from "../../api/request/pipeline";

const { Option } = Select;

const Services = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const initialValues = { serviceName: "" };
  const [data, setData] = useState([]);
  const [pipelineData, setPipelineData] = useState([]);

  const getServiceList = async () => {
    try {
      setIsLoading(true);
      const { data } = await getAllServiceApi();
      if (data.status) {
        setData(
          data?.data?.map((item) => {
            return {
              ...item,
              actions: (
                <button onClick={() => navigate(`/service/${item?.id}`)}>
                  <View />
                </button>
              ),
            };
          })
        );
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
    }
  };

  const getPipelineList = async () => {
    try {
      const { data } = await getAllPipelineApi();
      if (data.status) setPipelineData(data?.data);
    } catch (error) {
      if (error?.response?.data?.message)
        toast.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    getServiceList();
    getPipelineList();
  }, []);

  const createService = async (value) => {
    try {
      setIsLoading(true);
      const { data } = await CreateServiceApi(value);
      if (data.status) {
        setIsLoading(false);
        setIsOpen(false);
        toast.success(data.message);
        getServiceList();
      }
    } catch (error) {
      if (error?.response?.data?.message)
        toast.error(error?.response?.data?.message);
      setIsLoading(false);
    }
  };

  return (
    <div className="border bg-White rounded-xl py-5">
      <div className="flex items-center justify-between px-5 mb-5">
        <h2 className="font-semibold text-xl">Services</h2>
        <div>
          <button
            className="ms-auto m-2 px-4 py-2 bg-primary text-white rounded-md"
            onClick={(e) => setIsOpen(true)}
          >
            Create Service
          </button>
        </div>
      </div>
      <div>
        <SkeletonTable columns={columns} data={data} isLoading={isLoading} />
      </div>

      <ModalComponent
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        title={"Create Service"}
      >
        <Form
          autoComplete="off"
          form={form}
          initialValues={initialValues}
          onFinish={createService}
        >
          <Form.Item
            name="service_name"
            rules={[
              {
                required: true,
                message: "Please enter service name!",
              },
            ]}
          >
            <Input placeholder="Enter service name..." size="large" />
          </Form.Item>
          <Form.Item
            name="charge"
            rules={[
              {
                required: true,
                message: "Please enter service charge!",
              },
            ]}
          >
            <Input
              type="number"
              min={0}
              placeholder="Enter service charge..."
              size="large"
            />
          </Form.Item>
          <Form.Item
            name="pipeline_temp_id"
            className="mb-2"
            rules={[{ required: true, message: "Please select a pipeline!" }]}
          >
            <Select placeholder="Select Pipeline" size="large">
              {pipelineData?.map((item) => (
                <Option value={item?.id}>{item?.name}</Option>
              ))}
            </Select>
          </Form.Item>
          <div className="flex justify-center ">
            <LoadableButton
              className="bg-primary text-White uppercase text-base font-semibold w-full py-2 rounded-lg mt-2"
              type="submit"
              lable="Submit"
              loadingLable="Creating Service..."
              isLoading={isLoading}
            />
          </div>
        </Form>
      </ModalComponent>
    </div>
  );
};

export default Services;
