import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { Form, Input } from "antd";
import Add from "../../assets/Add";
import columns from "../../columns/pipeline/column";
import SkeletonTable from "../../components/table/SkeletonTable";
import LoadableButton from "../../components/button/LoadableButton";
import ModalComponent from "../../components/global/modals/ModalComponent";
import ConfirmationModal from "../../components/global/modals/ConfirmationModal";
import {
  CreatePipelineApi,
  DeletePipelineApi,
  getAllPipelineApi,
} from "../../api/request/pipeline";

const Pipeline = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [pipelineId, setPipelineId] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const initialValues = { name: "" };

  const getPipelineList = async () => {
    try {
      setIsLoading(true);
      const { data } = await getAllPipelineApi();
      if (data.status) {
        setData(
          data?.data?.map((item) => {
            return {
              ...item,
              action: (
                <div className="flex gap-5">
                  <button
                    onClick={() =>
                      navigate(`/pipeline/${item?.id}`, {
                        state: { pipeline: item },
                      })
                    }
                  >
                    <img
                      src="/assets/icons/edit.svg"
                      className="size-4"
                      alt="icon"
                    />
                  </button>
                  <button
                    onClick={() => {
                      setPipelineId(item?.id);
                      setIsDeleteModalOpen(true);
                    }}
                  >
                    <img
                      src="/assets/icons/delete.svg"
                      className="size-4"
                      alt="icon"
                    />
                  </button>
                </div>
              ),
            };
          })
        );
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getPipelineList();
  }, []);

  const createPipeline = async (value) => {
    try {
      setIsLoading(true);
      const { data } = await CreatePipelineApi(value);
      if (data.status) {
        setIsLoading(false);
        setIsOpen(false);
        toast.success(data.message);
        getPipelineList();
      }
    } catch (err) {
      setIsLoading(false);
    }
  };

  const deletePipeline = async () => {
    try {
      setIsLoading(true);
      const { data } = await DeletePipelineApi({ id: pipelineId });
      if (data.status) {
        setIsLoading(false);
        setIsDeleteModalOpen(false);
        toast.success(data.message);
        setPipelineId("");
        getPipelineList();
      }
    } catch (err) {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="border rounded-lg bg-White pb-4">
        <div className="py-5 px-4 flex items-center justify-between">
          <h2 className="font-semibold text-xl">Pipeline</h2>
          <button
            onClick={() => setIsOpen(true)}
            className="bg-secondary flex items-center gap-2 rounded-lg text-White py-2 px-3"
          >
            <Add />
            <span>New Pipeline</span>
          </button>
        </div>
        <div>
          <SkeletonTable columns={columns} data={data} isLoading={isLoading} />
        </div>
      </div>

      <ModalComponent
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        title="Create Pipeline"
      >
        <Form
          className="w-full"
          autoComplete="off"
          form={form}
          initialValues={initialValues}
          onFinish={createPipeline}
        >
          <div className="input-white">
            <Form.Item
              name="name"
              className="mb-0"
              rules={[
                { required: true, message: "Please enter pipeline name!" },
              ]}
            >
              <Input
                placeholder="Pipeline name"
                className="mt-1 w-full ps-4 py-2"
              />
            </Form.Item>
          </div>

          <div className="text-center mt-2">
            <LoadableButton
              className="bg-secondary text-sm text-white font-medium px-8 py-2 mt-6 rounded-lg themeHover duration-500"
              type="submit"
              lable="Cteate"
              isLoading={isLoading}
              loadingLable="Creating pipeline..."
            />
          </div>
        </Form>
      </ModalComponent>

      {/* --- Delete confirmation modal --- */}
      <ConfirmationModal
        isOpen={isDeleteModalOpen}
        setIsOpen={setIsDeleteModalOpen}
        message={`Are you sure you want to delete this pipeline?`}
        onConfirm={deletePipeline}
        isLoading={isLoading}
        loadingLabel={`Deleting pipeline...`}
      />
    </>
  );
};

export default Pipeline;
