import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
  Button,
  Drawer,
  Dropdown,
  Empty,
  Spin,
  Switch,
  Input,
  Select,
} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import LoadableButton from "../../../components/button/LoadableButton";
import ConfirmationModal from "../../../components/global/modals/ConfirmationModal";
import MyFreeEntry from "../../../components/questionContainer/formElements/FreeEntry";
import MyEmail from "../../../components/questionContainer/formElements/Email";
import MyNumber from "../../../components/questionContainer/formElements/Number";
import MyDatePicker from "../../../components/questionContainer/formElements/DatePicker";
import MyRadio from "../../../components/questionContainer/formElements/Radio";
import MyCheckBox from "../../../components/questionContainer/formElements/CheckBox";
import MyDropdown from "../../../components/questionContainer/formElements/Dropdown";
import MyFileUpload from "../../../components/questionContainer/formElements/FileUpload";
import YesNo from "../../../components/questionContainer/formElements/YesNo.jsx";
import TextEditor from "../../../components/questionContainer/formElements/TextEditor.jsx";
import {
  AddSectionApi,
  AddUpdateQuestionApi,
  DeleteQuestionApi,
  DeleteSectionApi,
  GetConditionalQuestionsApi,
  QuestionListApi,
  ReOrderQuestionApi,
} from "../../../api/request/service";

const { TextArea } = Input;

const Questions = ({ selectedSection, setSelectedSection }) => {
  const [questions, setQuestions] = useState([]);
  const [isQuestionListLoading, setIsQuestionListLoading] = useState(false);
  const [isAddQuestionLoading, setIsAddQuestionLoading] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [isDeleteSectionModalOpen, setIsDeleteSectionModalOpen] =
    useState(false);
  const [isDeleteSectionLoading, setIsDeleteSectionLoading] = useState(false);
  const [isDeleteQuestionLoading, setIsDeleteQuestionLoading] = useState(false);
  // ----- State of section settings ------ //
  const [allowSectionRepeat, setAllowSectionRepeat] = useState(false);
  const [isSectionSettingsOpen, setIsSectionSettingsOpen] = useState(false);
  const [section, setSection] = useState(selectedSection);
  const [sectionName, setSectionName] = useState(selectedSection?.section_name);
  const [isSectionSettingsLoading, setIsSectionSettingsLoading] =
    useState(false);
  // ----- State of question settings ------ //
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isSettingsLoading, setIsSettingsLoading] = useState(false);
  const [required, setRequired] = useState(false);
  const [conditional, setConditional] = useState(false);
  const [description, setDescription] = useState(false);
  const [descriptionText, setDescriptionText] = useState("");
  const [conditions, setConditions] = useState([]);
  const [conditionalQuestions, setConditionalQuestions] = useState([]);
  const [questionList, setQuestionList] = useState([]);
  const [isConditionalQuestionsLoading, setIsConditionalQuestionsLoading] =
    useState(false);

  const items = [
    {
      label: (
        <div
          className="grid grid-cols-2 place-items-center border-b py-1 px-4 text-black font-medium"
          onClick={() => addNewQuestion("input")}
        >
          <img src="/assets/icons/free-entry.svg" alt="icon" />
          <p>Free Entry</p>
        </div>
      ),
      key: "0",
    },
    {
      label: (
        <div
          className="grid grid-cols-2 place-items-center border-b py-1 px-4 text-black font-medium"
          onClick={() => addNewQuestion("email")}
        >
          <img src="/assets/icons/email-.svg" alt="icon" />
          <p>Email</p>
        </div>
      ),
      key: "1",
    },
    {
      label: (
        <div
          className="grid grid-cols-2 place-items-center border-b py-1 px-4 text-black font-medium"
          onClick={() => addNewQuestion("number")}
        >
          <img src="/assets/icons/number.svg" alt="icon" />
          <p>Number</p>
        </div>
      ),
      key: "2",
    },
    {
      label: (
        <div
          className="grid grid-cols-2 place-items-center border-b py-1 px-4 text-black font-medium"
          onClick={() => addNewQuestion("date")}
        >
          <img src="/assets/icons/date.svg" alt="icon" />
          <p>Date</p>
        </div>
      ),
      key: "3",
    },
    {
      label: (
        <div
          className="grid grid-cols-2 place-items-center border-b py-1 px-4 text-black font-medium"
          onClick={() => addNewQuestion("radio")}
        >
          <img src="/assets/icons/radio.svg" alt="icon" />
          <p>Radio Buttons</p>
        </div>
      ),
      key: "4",
    },
    {
      label: (
        <div
          className="grid grid-cols-2 place-items-center border-b py-1 px-4 text-black font-medium"
          onClick={() => addNewQuestion("checkbox")}
        >
          <img src="/assets/icons/checkbox.svg" alt="icon" />
          <p>Checkboxes</p>
        </div>
      ),
      key: "5",
    },
    {
      label: (
        <div
          className="grid grid-cols-2 place-items-center border-b py-1 px-4 text-black font-medium"
          onClick={() => addNewQuestion("dropdown")}
        >
          <img src="/assets/icons/dropdown.svg" alt="icon" />
          <p>Dropdown</p>
        </div>
      ),
      key: "6",
    },
    {
      label: (
        <div
          className="grid grid-cols-2 place-items-center border-b py-1 px-4 text-black font-medium"
          onClick={() => addNewQuestion("yes-no")}
        >
          <img src="/assets/icons/yes-no.svg" alt="icon" />
          <p>Yes/No</p>
        </div>
      ),
      key: "7",
    },
    {
      label: (
        <div
          className="grid grid-cols-2 place-items-center border-b py-1 px-4 text-black font-medium"
          onClick={() => addNewQuestion("file")}
        >
          <img src="/assets/icons/file-upload.svg" alt="icon" />
          <p>File Upload</p>
        </div>
      ),
      key: "8",
    },
  ];

  useEffect(() => {
    if (selectedSection) {
      setSection(selectedSection);
      setSectionName(selectedSection?.section_name);
      getQuestions();
    }
  }, [selectedSection]);

  const fetchComponent = (data) => {
    switch (data?.field_type) {
      case "input":
        return <MyFreeEntry question={data} />;
      case "email":
        return <MyEmail question={data} />;
      case "number":
        return <MyNumber question={data} />;
      case "date":
        return <MyDatePicker question={data} />;
      case "radio":
        return <MyRadio question={data} />;
      case "checkbox":
        return <MyCheckBox question={data} />;
      case "dropdown":
        return <MyDropdown question={data} />;
      case "yes-no":
        return <YesNo question={data} />;
      case "file":
        return <MyFileUpload question={data} />;
      default:
        break;
    }
  };

  const fetchQuestionType = {
    input: "Free Entry",
    email: "Email",
    number: "Number",
    date: "Date Picker",
    radio: "Radio Buttons",
    checkbox: "Check Boxes",
    dropdown: "Dropdown",
    file: "File Upload",
    "yes-no": "Yes/No",
  };

  const getQuestions = async (isLoad = true) => {
    if (!selectedSection) return;

    try {
      if (isLoad) setIsQuestionListLoading(true);
      const { data } = await QuestionListApi({
        section_id: selectedSection?.id,
      });
      if (data.status) {
        setQuestions(
          data?.data[0]?.questions?.map((item) => {
            return {
              id: item?.id,
              question: item?.is_label ? (
                <TextEditor question={item} />
              ) : (
                fetchComponent(item)
              ),
              ...item,
            };
          })
        );
      }
      setIsQuestionListLoading(false);
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      }
      setIsQuestionListLoading(false);
    }
  };

  const addNewQuestion = async (type) => {
    try {
      setIsAddQuestionLoading(true);
      const { data } = await AddUpdateQuestionApi({
        section_id: selectedSection.id,
        field_type: type,
      });
      if (data.status) {
        const { data } = await QuestionListApi({
          section_id: selectedSection?.id,
        });
        if (data.status) {
          setQuestions(
            data?.data[0]?.questions?.map((item) => {
              return {
                id: item?.id,
                question: item?.is_label ? (
                  <TextEditor question={item} />
                ) : (
                  fetchComponent(item)
                ),
                ...item,
              };
            })
          );
        }
      }
      setIsAddQuestionLoading(false);
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      }
      setIsAddQuestionLoading(false);
    }
  };

  const addNewTextBlock = async () => {
    try {
      setIsAddQuestionLoading(true);
      const { data } = await AddUpdateQuestionApi({
        section_id: selectedSection.id,
        is_label: true,
      });
      if (data.status) {
        const { data } = await QuestionListApi({
          section_id: selectedSection?.id,
        });
        if (data.status) {
          setQuestions(
            data?.data[0]?.questions?.map((item) => {
              return {
                id: item?.id,
                question: item?.is_label ? (
                  <TextEditor question={item} />
                ) : (
                  fetchComponent(item)
                ),
                ...item,
              };
            })
          );
        }
      }
      setIsAddQuestionLoading(false);
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      }
      setIsAddQuestionLoading(false);
    }
  };

  const deleteQuestion = async (question) => {
    if (question?.is_editable === 0) return;
    setSelectedQuestion(question);
    try {
      setIsDeleteQuestionLoading(true);
      const { data } = await DeleteQuestionApi({
        service_question_id: question?.id,
      });
      if (data?.status) {
        setQuestions(questions.filter((item) => item.id !== question?.id));
        setSelectedQuestion(null);
      }
      setIsDeleteQuestionLoading(false);
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      }
      setIsDeleteQuestionLoading(false);
    }
  };

  const deleteSection = async () => {
    try {
      setIsDeleteSectionLoading(true);
      const { data } = await DeleteSectionApi({
        service_section_id: selectedSection?.id,
      });
      if (data?.status) {
        setSelectedSection(null);
        setIsDeleteSectionModalOpen(false);
        setQuestions([]);
      }
      setIsDeleteSectionLoading(false);
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      }
      setIsDeleteSectionLoading(false);
    }
  };

  const handleQuestionOnDragEnd = async (result) => {
    if (!result.destination) return;
    const items = Array.from(questions);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setQuestions(items);
    try {
      let params = {
        question_id: result?.draggableId,
        field_order: result?.destination?.index + 1,
      };
      await ReOrderQuestionApi(params);
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      }
    }
  };

  const openSettings = (question) => {
    setSelectedQuestion(question);
    getConditionalQuestions();
    setRequired(question?.is_required ? true : false);
    setDescription(question?.description ? true : false);
    setDescriptionText(question?.description);
    setConditional(question?.condition?.length ? true : false);
    setConditions(
      question?.condition?.map((item, index) => {
        return { id: index + 1, ...item };
      }) || []
    );
    setIsSettingsOpen(true);
  };

  const closeSettings = () => {
    setIsSettingsOpen(false);
    setSelectedQuestion(null);
    setRequired(false);
    setDescription(false);
    setDescriptionText("");
    setConditions([]);
    setConditional(false);
  };

  const handleSettings = async () => {
    try {
      if (description && !descriptionText) {
        toast.error("Please enter description first.");
        return;
      }
      setIsSettingsLoading(true);
      let params = {
        question_id: selectedQuestion?.id,
        section_id: selectedQuestion?.service_section_id,
        is_required: required,
        description: description ? descriptionText : null,
        condition: conditional
          ? conditions?.length
            ? conditions
                ?.map(({ id, ...rest }) => rest)
                ?.filter(
                  (item) => item.question_id !== null && item.answer !== null
                )
            : null
          : null,
      };
      const { data } = await AddUpdateQuestionApi(params);
      if (data?.status) {
        setQuestions((prevQuestions) =>
          prevQuestions.map((question) =>
            question.id === selectedQuestion?.id
              ? { ...question, ...params }
              : question
          )
        );
        closeSettings();
        getQuestions(false);
      }
      setIsSettingsLoading(false);
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      }
      setIsSettingsLoading(false);
    }
  };

  const createDropdownOption = (id) => {
    let question = conditionalQuestions?.find((item) => item?.id === id);
    return (
      question?.options?.map((item) => {
        return { value: item, label: item };
      }) || []
    );
  };

  const addNewCondition = () => {
    setConditions((prevConditions) => [
      ...prevConditions,
      { id: conditions?.length + 1, question_id: null, answer: null },
    ]);
  };

  const removeCondition = (id) => {
    setConditions(conditions.filter((item) => item.id !== id));
  };

  const onQuestionChange = (id, value) => {
    setConditions((prevCondition) =>
      prevCondition.map((item) =>
        item.id === id ? { ...item, question_id: value } : item
      )
    );
  };

  const onAnswerChange = (id, value) => {
    setConditions((prevCondition) =>
      prevCondition.map((item) =>
        item.id === id ? { ...item, answer: value } : item
      )
    );
  };

  const getConditionalQuestions = async () => {
    try {
      setIsConditionalQuestionsLoading(true);
      const { data } = await GetConditionalQuestionsApi({
        service_type_id: selectedSection?.service_type_id,
      });
      if (data?.status) {
        setConditionalQuestions(data?.data);
        setQuestionList(
          data?.data?.map((item) => {
            return { value: item?.id, label: item?.field_label };
          })
        );
      }
      setIsConditionalQuestionsLoading(false);
    } catch (error) {
      setIsConditionalQuestionsLoading(false);
    }
  };

  const openSectionSettings = () => {
    getConditionalQuestions();
    setAllowSectionRepeat(section?.allow_repeat ? true : false);
    setConditional(section?.condition?.length ? true : false);
    setConditions(
      section?.condition?.map((item, index) => {
        return { id: index + 1, ...item };
      }) || []
    );
    setIsSectionSettingsOpen(true);
  };

  const closeSectionSettings = () => {
    setIsSectionSettingsOpen(false);
    setConditions([]);
    setAllowSectionRepeat(false);
    setConditional(false);
  };

  const handleSectionSettings = async () => {
    try {
      setIsSectionSettingsLoading(true);
      let params = {
        section_id: selectedSection?.id,
        service_type_id: selectedSection?.service_type_id,
        section_name: sectionName,
        allow_repeat: allowSectionRepeat,
        condition: conditional
          ? conditions?.length
            ? conditions
                ?.map(({ id, ...rest }) => rest)
                ?.filter(
                  (item) => item.question_id !== null && item.answer !== null
                )
            : null
          : null,
      };
      const { data } = await AddSectionApi(params);
      if (data?.status) {
        setSelectedSection({ ...selectedSection, ...params });
        setSection({ ...section, ...params });
        closeSectionSettings();
      }
      setIsSectionSettingsLoading(false);
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      }
      setIsSectionSettingsLoading(false);
    }
  };

  return (
    <>
      <div className="border rounded-xl bg-White">
        {selectedSection && (
          <div className="border-b p-3 flex gap-3 justify-between items-center">
            <p className="font-semibold text-primary line-clamp-1 break-words">
              {section?.section_name}
            </p>
            <div className="flex items-center gap-3">
              <div onClick={openSectionSettings}></div>
              <div className="flex items-center gap-5">
                <img
                  src="/assets/icons/settings.svg"
                  alt="icon"
                  className="cursor-pointer size-5"
                  onClick={openSectionSettings}
                />
                <img
                  src="/assets/icons/delete.svg"
                  alt="icon"
                  className="cursor-pointer size-5"
                  onClick={() => setIsDeleteSectionModalOpen(true)}
                />
              </div>
            </div>
          </div>
        )}

        <div className="p-4">
          {isQuestionListLoading ? (
            <div className="flex flex-col items-center gap-2 my-16">
              <Spin />
              <p className="text-secondaryLight1">Loading...</p>
            </div>
          ) : (
            <div>
              {questions.length ? (
                <DragDropContext onDragEnd={handleQuestionOnDragEnd}>
                  <Droppable droppableId="sections">
                    {(provided) => (
                      <div
                        className="section-list"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {questions.map((question, index) => (
                          <Draggable
                            key={question.id}
                            draggableId={question.id}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                className="section-item"
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <div className="flex items-center justify-between">
                                  <p className="text-secondaryLight1 flex gap-1">
                                    {fetchQuestionType[question?.field_type] ||
                                      "Text Block"}{" "}
                                    <p className="text-red-600 text-lg">
                                      {question?.is_required ? "*" : ""}
                                    </p>
                                  </p>
                                  {question?.condition?.length ? (
                                    <p className="bg-green-500 px-2 text-[12px] text-secondaryLight rounded-full">
                                      Conditions
                                    </p>
                                  ) : null}
                                </div>
                                <div className="flex items-start justify-start font-semibold rounded-md gap-3">
                                  <div className="w-full">
                                    {question?.question}
                                  </div>
                                  <div className="flex items-center gap-3 mt-[23px]">
                                    <img
                                      src="/assets/icons/settings.svg"
                                      alt="icon"
                                      className="cursor-pointer size-5"
                                      onClick={() => openSettings(question)}
                                    />
                                    {isDeleteQuestionLoading &&
                                    selectedQuestion?.id === question?.id ? (
                                      <Spin
                                        indicator={<LoadingOutlined spin />}
                                        size="default"
                                      />
                                    ) : (
                                      <img
                                        src="/assets/icons/delete.svg"
                                        alt="icon"
                                        className="cursor-pointer size-5"
                                        onClick={() => deleteQuestion(question)}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                        {isAddQuestionLoading && (
                          <div className="flex flex-col items-center gap-2 my-12">
                            <Spin />
                            <p className="text-secondaryLight1">Loading...</p>
                          </div>
                        )}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              ) : (
                <>
                  {isAddQuestionLoading ? (
                    <div className="flex flex-col items-center gap-2 my-12">
                      <Spin />
                      <p className="text-secondaryLight1">Loading...</p>
                    </div>
                  ) : (
                    <Empty description={false} className="my-24" />
                  )}
                </>
              )}
            </div>
          )}
        </div>

        <div className="p-4">
          <div className="grid grid-cols-2 gap-4">
            <Dropdown menu={{ items }} trigger={["click"]}>
              <div className="p-3 bg-bgColor rounded-md cursor-pointer flex items-center justify-center gap-3">
                <img
                  src="/assets/icons/add-circle-black.svg"
                  className="size-6"
                  alt="icon"
                />
                <p className="font-medium">Add Question Field</p>
              </div>
            </Dropdown>
            <div
              className="p-3 bg-bgColor rounded-md cursor-pointer flex items-center justify-center gap-3"
              onClick={addNewTextBlock}
            >
              <img
                src="/assets/icons/add-circle-black.svg"
                className="size-6"
                alt="icon"
              />
              <p className="font-medium">Add Text Block</p>
            </div>
          </div>
        </div>
      </div>

      <ConfirmationModal
        isOpen={isDeleteSectionModalOpen}
        setIsOpen={setIsDeleteSectionModalOpen}
        message="Are you sure you want to remove this section?"
        onConfirm={deleteSection}
        isLoading={isDeleteSectionLoading}
        loadingLabel="Removing section..."
      />

      <Drawer
        title="Settings"
        open={isSettingsOpen}
        onClose={closeSettings}
        width={620}
        maskClosable={false}
      >
        <div className="border rounded-xl p-4">
          <div className="flex items-center gap-4 border-b pb-3">
            <Switch value={required} onChange={() => setRequired(!required)} />
            <p className="font-semibold text-lg">Required</p>
          </div>
          <p className="mt-4 text-secondaryLight1">
            It is mandatory to respond to this question to submit the organizer.
          </p>
        </div>

        <div className="border rounded-xl p-4 mt-6">
          <div className="flex items-center gap-4 border-b pb-3">
            <Switch
              value={conditional}
              onChange={() => setConditional(!conditional)}
            />
            <p className="font-semibold text-lg">Conditional</p>
          </div>
          <p className="mt-4 text-secondaryLight1">
            Ask question only in certain scenarios.
          </p>

          {conditional && (
            <div>
              {isConditionalQuestionsLoading ? (
                <div className="flex flex-col items-center gap-2 my-10">
                  <Spin />
                  <p className="text-secondaryLight1">Loading...</p>
                </div>
              ) : (
                <div>
                  {conditionalQuestions?.length > 0 ? (
                    <div>
                      <div className="mt-4 flex justify-end">
                        <button
                          className="flex items-center gap-1 text-primary font-semibold"
                          onClick={addNewCondition}
                        >
                          <img
                            src="/assets/icons/add-circle-primary.svg"
                            className="size-4"
                            alt="icon"
                          />
                          Add
                        </button>
                      </div>
                      <div>
                        {conditions?.map((condition, index) => (
                          <div className="flex items-center gap-3">
                            <div
                              className="w-full grid grid-cols-2 gap-4 my-4"
                              key={index}
                            >
                              <Select
                                showSearch
                                options={questionList}
                                placeholder="Question"
                                size="large"
                                value={condition?.question_id}
                                onChange={(value) =>
                                  onQuestionChange(condition?.id, value)
                                }
                              />
                              <Select
                                showSearch
                                options={createDropdownOption(
                                  condition?.question_id
                                )}
                                placeholder="Answer"
                                size="large"
                                value={condition?.answer}
                                onChange={(value) =>
                                  onAnswerChange(condition?.id, value)
                                }
                              />
                            </div>
                            <img
                              src="/assets/icons/delete.svg"
                              alt="icon"
                              className="cursor-pointer size-5"
                              onClick={() => removeCondition(condition?.id)}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <Empty description={false} className="my-2" />
                  )}
                </div>
              )}
            </div>
          )}
        </div>

        <div className="border rounded-xl p-4 mt-6">
          <div className="flex items-center gap-4 border-b pb-3">
            <Switch
              value={description}
              onChange={() => setDescription(!description)}
            />
            <p className="font-semibold text-lg">Description</p>
          </div>
          <p className="mt-4  ">
            Add instructional text to help clients answer your question.
          </p>
          {description && (
            <TextArea
              className="mt-4"
              placeholder="Question description..."
              autoSize={{ minRows: 3, maxRows: 5 }}
              value={descriptionText}
              onChange={(e) => setDescriptionText(e.target.value)}
            />
          )}
        </div>

        <div className="mt-6 flex gap-4">
          <LoadableButton
            className="bg-primary px-10 text-white text-base font-semibold py-2 rounded-lg"
            isLoading={isSettingsLoading}
            lable="Save"
            loadingLable="Saving..."
            onClick={handleSettings}
          />
          <Button
            size="large"
            type="primary"
            ghost
            className="py-5 px-10 font-semibold"
            onClick={closeSettings}
          >
            Cancel
          </Button>
        </div>
      </Drawer>

      <Drawer
        title="Section settings"
        open={isSectionSettingsOpen}
        onClose={closeSectionSettings}
        width={620}
        maskClosable={false}
      >
        <div className="border rounded-xl p-4">
          <div className="flex items-center gap-4">
            <Switch
              value={allowSectionRepeat}
              onChange={() => setAllowSectionRepeat(!allowSectionRepeat)}
            />
            <p className="font-semibold text-lg">Allow client to repeat</p>
          </div>
        </div>

        <div className="border rounded-xl p-4 mt-6">
          <div className="border-b pb-3">
            <p className="font-semibold text-lg">Section name</p>
          </div>
          <Input
            size="large"
            placeholder="Section name"
            className="mt-4"
            value={sectionName}
            onChange={(e) => setSectionName(e.target.value)}
          />
        </div>

        <div className="border rounded-lg p-4 mt-6">
          <div className="flex items-center gap-4 border-b pb-3">
            <Switch
              value={conditional}
              onChange={() => setConditional(!conditional)}
            />
            <p className="font-semibold text-lg">Conditional</p>
          </div>
          <p className="mt-4 text-secondaryLight1">
            Ask question only in certain scenarios.
          </p>

          {conditional && (
            <div>
              {isConditionalQuestionsLoading ? (
                <div className="flex flex-col items-center gap-2 my-10">
                  <Spin />
                  <p className="text-secondaryLight1">Loading...</p>
                </div>
              ) : (
                <div>
                  {conditionalQuestions?.length > 0 ? (
                    <div>
                      <div className="mt-4 flex justify-end">
                        <button
                          className="flex items-center gap-1 text-primary font-semibold"
                          onClick={addNewCondition}
                        >
                          <img
                            src="/assets/icons/add-circle-primary.svg"
                            className="size-4"
                            alt="icon"
                          />
                          Add
                        </button>
                      </div>
                      <div>
                        {conditions?.map((condition, index) => (
                          <div className="flex items-center gap-3">
                            <div
                              className="w-full grid grid-cols-2 gap-4 my-4"
                              key={index}
                            >
                              <Select
                                showSearch
                                options={questionList}
                                placeholder="Question"
                                size="large"
                                value={condition?.question_id}
                                onChange={(value) =>
                                  onQuestionChange(condition?.id, value)
                                }
                              />
                              <Select
                                showSearch
                                options={createDropdownOption(
                                  condition?.question_id
                                )}
                                placeholder="Answer"
                                size="large"
                                value={condition?.answer}
                                onChange={(value) =>
                                  onAnswerChange(condition?.id, value)
                                }
                              />
                            </div>
                            <img
                              src="/assets/icons/delete.svg"
                              alt="icon"
                              className="cursor-pointer size-5"
                              onClick={() => removeCondition(condition?.id)}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <Empty description={false} className="my-2" />
                  )}
                </div>
              )}
            </div>
          )}
        </div>

        <div className="mt-6 flex gap-4">
          <LoadableButton
            className="bg-primary px-10 text-white uppercase text-base font-semibold py-2 rounded-lg"
            isLoading={isSectionSettingsLoading}
            lable="Save"
            loadingLable="Saving..."
            onClick={handleSectionSettings}
          />
          <Button
            size="large"
            type="primary"
            ghost
            className="py-5 px-10 uppercase font-semibold"
            onClick={closeSectionSettings}
          >
            Cancel
          </Button>
        </div>
      </Drawer>
    </>
  );
};

export default Questions;
