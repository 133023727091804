import { Form, Input, Switch } from "antd";
import React, { useEffect, useState } from "react";
import {
  GetTagListApi,
  AddUpdateTagApi,
  DeleteTagApi,
  UpdateTagStatusApi,
} from "../../../api/request/setting/tag";
import moment from "moment";
import toast from "react-hot-toast";
import Add from "../../../assets/Add";
import SkeletonTable from "../../../components/table/SkeletonTable";
import column from "../../../columns/setting/tags";
import ModalComponent from "../../../components/modals/ModalComponent";
import LoadableButton from "../../../components/button/LoadableButton";
import ConfirmationModal from "../../../components/modals/ConfirmationModal";

export default function Tags() {
  const [form] = Form.useForm();
  const initialTagValues = { name: "" };
  const [isAddEditTagModalOpen, setIsAddEditTagModalOpen] = useState(false);
  const [isAddEditTagLoading, setIsAddEditTagLoading] = useState(false);
  const [isEditTag, setIsEditTag] = useState(false);
  const [isTagDelete, setIsTagDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [selectedTag, setSelectedTag] = useState("");
  const [tags, setTags] = useState([]);
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
  const [isStatusLoading, setIsStatusLoading] = useState(false);
  const [isFormChanged, setIsFormChanged] = useState(false);

  const handleFieldsChange = (_, allFields) => {
    const isChanged = allFields.some((field) => field.touched);
    setIsFormChanged(isChanged);
  };

  const getTagList = async () => {
    setIsLoading(true);
    try {
      const { data } = await GetTagListApi({ type: "client" });
      if (data.status) {
        setTags(
          data?.data?.map((item) => {
            return {
              ...item,
              status: (
                <Switch
                  checked={item?.status == "active" ? true : false}
                  onClick={() => handleTagStatus(item)}
                  checkedChildren="On"
                  unCheckedChildren="Off"
                />
              ),
              created_at: item?.created_at
                ? moment(item?.created_at).format("lll")
                : "--",
              action: (
                <div className="flex items-center gap-3">
                  <button onClick={() => handleEditModal(item)}>
                    <img
                      src="/assets/icons/edit.svg"
                      className="size-4"
                      alt="icon"
                    />
                  </button>
                  <button
                    onClick={() => {
                      setSelectedTag(item);
                      setIsTagDelete(true);
                    }}
                  >
                    <img
                      src="/assets/icons/delete.svg"
                      className="size-4"
                      alt="icon"
                    />
                  </button>
                </div>
              ),
            };
          })
        );
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getTagList();
    setIsEditTag(false);
  }, []);

  const handleEditModal = (tag) => {
    setIsAddEditTagModalOpen(true);
    setIsEditTag(true);
    setSelectedTag(tag);
    form.setFieldsValue({ name: tag?.name });
  };

  const handleAddEditTag = async (values) => {
    try {
      setIsAddEditTagLoading(true);
      const params = isEditTag
        ? { ...values, id: selectedTag?.id, type: "client" }
        : { ...values, type: "client" };
      const { data } = await AddUpdateTagApi(params);
      if (data?.status) {
        setIsAddEditTagModalOpen(false);
        getTagList();
        form.resetFields();
        toast.success(data?.message);
        setIsFormChanged(false);
      }
      setIsAddEditTagLoading(false);
      setIsEditTag(false);
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      }
      setIsAddEditTagLoading(false);
      setIsEditTag(false);
    }
  };

  const handleTagStatus = (tag) => {
    setSelectedTag(tag);
    setIsStatusModalOpen(true);
  };

  const handleStatuChange = async () => {
    try {
      setIsStatusLoading(true);
      let params = {
        id: selectedTag?.id,
        status: selectedTag?.status === "active" ? "inactive" : "active",
      };
      const { data } = await UpdateTagStatusApi(params);
      if (data?.status) {
        toast.success(data?.message);
        getTagList();
        setIsStatusModalOpen(false);
      }
      setIsStatusLoading(false);
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      }
      setIsStatusLoading(false);
    }
  };

  const handleDeleteTag = async () => {
    setIsLoadingDelete(true);
    try {
      const { data } = await DeleteTagApi({ id: selectedTag?.id });
      if (data?.status) {
        setIsTagDelete(false);
        getTagList();
        toast.success(data?.message);
      }
      setIsLoadingDelete(false);
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      }
      setIsLoadingDelete(false);
    }
  };

  useEffect(() => {
    if (!isAddEditTagModalOpen) {
      form.resetFields();
    }
  }, [isAddEditTagModalOpen]);

  return (
    <div>
      <div className="border rounded-xl my-5 bg-white">
        <div className="flex justify-between mx-5 mt-5">
          <p className=" font-semibold text-2xl">Tags</p>
          <div className="flex gap-3">
            <button
              onClick={() => setIsAddEditTagModalOpen(true)}
              className="bg-primary text-white rounded-lg py-2 px-4 flex items-center gap-1"
            >
              <Add />
              Add Tag
            </button>
          </div>
        </div>
        <div className="my-6">
          <SkeletonTable columns={column} data={tags} isLoading={isLoading} />
        </div>
      </div>

      {/* Add Tags */}
      <ModalComponent
        isOpen={isAddEditTagModalOpen}
        setIsOpen={setIsAddEditTagModalOpen}
        width={500}
      >
        <p className="mb-4 text-lg font-semibold text-center">
          {!isEditTag ? "Add Tag" : "Edit Tag"}
        </p>
        <Form
          autoComplete="off"
          form={form}
          initialValues={initialTagValues}
          onFinish={handleAddEditTag}
          onFieldsChange={handleFieldsChange}
        >
          <Form.Item
            name="name"
            className="mt-6"
            rules={[{ required: true, message: "Please enter tag name!" }]}
          >
            <Input placeholder="Enter tag name" size="large" />
          </Form.Item>
          <div className="text-center">
            <LoadableButton
              className="bg-primary text-white uppercase text-base font-semibold mt-2 py-2 rounded-lg px-4"
              type="submit"
              lable={!isEditTag ? "Submit" : "Update"}
              loadingLable={!isEditTag ? "Adding tag..." : "Updating tag..."}
              isLoading={isAddEditTagLoading}
              disabled={!isFormChanged || isAddEditTagLoading}
            />
          </div>
        </Form>
      </ModalComponent>

      {/* ------ Confirmation modal for status ------- */}
      <ConfirmationModal
        isOpen={isStatusModalOpen}
        setIsOpen={setIsStatusModalOpen}
        message="Are you sure, you want to change tag status?"
        onConfirm={handleStatuChange}
        isLoading={isStatusLoading}
        loadingLabel="Changing status..."
      />

      {/* Delete tag */}
      <ConfirmationModal
        isOpen={isTagDelete}
        setIsOpen={setIsTagDelete}
        message="Are you sure, want to delete this tag?"
        onConfirm={() => handleDeleteTag()}
        isLoading={isLoadingDelete}
        loadingLabel="Removing tag..."
      />
    </div>
  );
}
