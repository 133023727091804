import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SkeletonTable from "../../components/table/SkeletonTable";
import columns from "../../columns/services/column";
import View from "../../assets/View";
import { getAllServiceApi } from "../../api/request/service";

const Files = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  const getServiceList = async () => {
    try {
      setIsLoading(true);
      const { data } = await getAllServiceApi();
      if (data.status) {
        setData(
          data?.data?.map((item) => {
            return {
              ...item,
              actions: (
                <button onClick={() => navigate(`/file/${item?.id}`)}>
                  <View />
                </button>
              ),
            };
          })
        );
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getServiceList();
  }, []);

  return (
    <div className="border bg-White rounded-xl py-5">
      <div className="flex items-center justify-between px-5 mb-5">
        <h2 className="font-semibold text-xl">Files</h2>
      </div>
      <div>
        <SkeletonTable columns={columns} data={data} isLoading={isLoading} />
      </div>
    </div>
  );
};

export default Files;
