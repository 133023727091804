
import Forgot from '../pages/auth/Forgot.jsx';
import Login from '../pages/auth/Login.jsx';
import Otp from '../pages/auth/Otp.jsx';
import Register from '../pages/auth/Register.jsx';
import ResetPassword from '../pages/auth/ResetPassword.jsx';
import SignUp from '../pages/auth/SignUp.jsx';
import Chat from '../pages/chat/Chat.jsx';
import Clients from '../pages/clients/Clients.jsx';
import ClientDetails from '../pages/clients/components/ClientDetails.jsx';
import Communication from '../pages/communication/Communication.jsx';
import Dashboard from '../pages/dashboard/Dashboard.jsx';
// import Trash from '../pages/document/components/Trash.jsx';
// import Document from '../pages/document/Document.jsx';
import Files from '../pages/files/Files.jsx';
import ReviewFile from '../pages/files/ReviewFile.jsx';
import ViewFile from '../pages/files/ViewFile.jsx';
// import Invoice from '../pages/invoice/Invoice.jsx';
import Task from '../pages/pipeline/components/Task.jsx';
import TaskDetail from '../pages/pipeline/components/TaskDetail.jsx';
import Pipeline from '../pages/pipeline/Pipeline.jsx';
import ViewPipeline from '../pages/pipeline/ViewPipeline.jsx';
import Services from '../pages/services/Services.jsx';
import ViewService from '../pages/services/ViewService.jsx';
import Tags from '../pages/setting/tags/Tags.jsx';
import Team from '../pages/team/Team.jsx';

export const initialRoutes = () => {
    const routes = [
        // ============ Auth ===============
        { path: "/login", type: "auth", component: Login, title: 'Login' },
        { path: "/signup", type: "auth", component: SignUp, title: 'SignUp' },
        { path: "/register", type: "auth", component: Register, title: 'Register' },
        { path: "/otp", type: "auth", component: Otp, title: 'Otp' },
        { path: "/forget_password", type: "auth", component: Forgot, title: 'ForgotPassword' },
        { path: "/change_password/:token", type: "auth", component: ResetPassword, title: 'ResetPassword' },
        // ============ Public =============

        // ============ Privet ==============
        { path: "/", type: "private", component: Dashboard, title: "Dashboard" },
        { path: "/dashboard", type: "private", component: Dashboard, title: "Dashboard" },

        { path: "/files", type: "private", component: Files, title: "Files" },
        { path: "/file/:fileTypeId", type: "private", component: ViewFile, title: "Files" },
        { path: "/file/:fileTypeId/:id", type: "private", component: ReviewFile, title: "Files" },

        { path: "/services", type: "private", component: Services, title: "Services" },
        { path: "/service/:serviceTypeId", type: "private", component: ViewService, title: "Services" },
        
        // { path: "/documents", type: "private", component: Document, title: "Document" },
        // { path: "/documents_trash", type: "private", component: Trash, title: "Document Trash" },
        { path: "/team", type: "private", component: Team, title: "Team" },
        { path: "/clients", type: "private", component: Clients, title: "Clients" },
        { path: "/client_details/:id", type: "private", component: ClientDetails, title: "Clients" },
        { path: "/pipeline", type: "private", component: Pipeline, title: "Pipeline" },
        { path: "/pipeline/:id", type: "private", component: ViewPipeline, title: "Pipeline" },
        { path: "/task", type: "private", component: Task, title: "Task" },
        { path: "/task_detail", type: "private", component: TaskDetail, title: "Task Detail" },
        { path: "/tags", type: "private", component: Tags, title: "Tags" },
        // { path: "/invoice", type: "private", component: Invoice, title: "Invoice" },
        { path: "/chat", type: "private", component: Communication, title: "Chat" },
    ]
    return routes;
}