import React, { useEffect, useState } from "react";
import SkeletonTable from "../../components/table/SkeletonTable";
import { Input } from "antd";
import columns from "../../columns/clients/column";
import View from "../../assets/View";
import { useNavigate } from "react-router-dom";
import { getclientlist } from "../../api/request/service";

const Clients = () => {
     const navigate = useNavigate();
     const [data, setData] = useState([]);
     //  const [filteredData, setFilteredData] = useState([]);
     const [searchTerm, setSearchTerm] = useState("");
     const [filteredData, setFilteredData] = useState(data);
     const [isLoading, setIsLoading] = useState(false);
     const handleSearch = (e) => {
          const value = e.target.value.toLowerCase(); // Convert to lowercase for case-insensitive search
          setSearchTerm(value);

          const filtered = data.filter((item) => item.name.toLowerCase().includes(value) || item.email.toLowerCase().includes(value));
          setFilteredData(
               filtered.map((item) => {
                    return {
                         ...item,
                         action: (
                              <button
                                   onClick={() =>
                                        navigate(`/client_details/${item?.id}`, {
                                             state: { pipeline_temp_id: item?.pipeline_temp_id },
                                        })
                                   }
                              >
                                   <View />
                              </button>
                         ),
                    };
               })
          );
     };

     const getClientList = async () => {
          try {
               setIsLoading(true);
               const { data } = await getclientlist();
               if (data.status) {
                    const clientData = data?.data?.map((item) => {
                         return {
                              ...item,
                              action: (
                                   <button
                                        onClick={() =>
                                             navigate(`/client_details/${item?.id}`, {
                                                  state: { pipeline_temp_id: item?.pipeline_temp_id },
                                             })
                                        }
                                   >
                                        <View />
                                   </button>
                              ),
                         };
                    });
                    setData(clientData);
                    setFilteredData(clientData);
                    setIsLoading(false);
               }
          } catch (err) {
               setIsLoading(false);
          }
     };

     useEffect(() => {
          // setData(tableData.map((item) => {
          //     return {
          //         ...item,
          //         action: <button onClick={() => navigate(`/client_details/${item.id}`)}> <View /> </button>
          //     }
          // }));
          getClientList();
     }, []);

     return (
          <div className="border rounded-lg mt-5 bg-White">
               <div className="py-5 px-4 flex items-center justify-between">
                    <p className="font-semibold text-xl">Clients</p>
                    <Input
                         size="large"
                         placeholder="Search here..."
                         className="xl:w-80"
                         onChange={handleSearch}
                         value={searchTerm}
                         prefix={
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#B3B3B3" class="size-6">
                                   <path
                                        fill-rule="evenodd"
                                        d="M10.5 3.75a6.75 6.75 0 1 0 0 13.5 6.75 6.75 0 0 0 0-13.5ZM2.25 10.5a8.25 8.25 0 1 1 14.59 5.28l4.69 4.69a.75.75 0 1 1-1.06 1.06l-4.69-4.69A8.25 8.25 0 0 1 2.25 10.5Z"
                                        clip-rule="evenodd"
                                   />
                              </svg>
                         }
                    />
               </div>
               <div className="mt-5">
                    <SkeletonTable columns={columns} data={filteredData} isLoading={isLoading} />
               </div>
          </div>
     );
};

export default Clients;
