const defaultRender = (value) => (value ? value : '--');

const columns = [
    {
        title: 'File',
        dataIndex: 'file',
        render: defaultRender,
    },
    {
        title: 'Name',
        dataIndex: 'name',
        render: defaultRender,
    },
    {
        title: 'Email',
        dataIndex: 'email',
        render: defaultRender,
    },
    {
        title: 'Submit',
        dataIndex: 'submit',
        render: defaultRender,
    },
    // {
    //     title: 'Tag',
    //     dataIndex: 'tag',
    //     render: defaultRender,
    //     width: 200
    // },
    {
        title: 'Assigned To',
        dataIndex: 'employee_name',
        render: defaultRender,
    },
    {
        title: 'Start Date',
        dataIndex: 'startDate',
        render: defaultRender,
    },
    {
        title: 'Status',
        dataIndex: 'status',
        render: defaultRender,
    },
    {
        title:'Action',
        dataIndex: 'action',
        fixed: 'right',
    },
];

export default columns;