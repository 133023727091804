import { Avatar } from "antd";
import React from "react";

export default function Information({ client }) {
     const getInitials = (name) => {
          const nameParts = name?.trim()?.split(" ");
          if (nameParts) {
               const initials = nameParts[0][0] + nameParts[nameParts.length - 1][0];
               return initials.toUpperCase();
          }
     };

     return (
          <div>
               <div className="grid md:grid-cols-3 gap-5 m-4">
                    <div className="md:col-span-2 border rounded-lg">
                         <div className="p-4">
                              <p className="text-lg font-semibold">Account details</p>
                         </div>

                         <div className="border-y mx-4 mb-4 flex items-center gap-5 md:p-4">
                              <Avatar size={60} src={client?.profile_image} className="bg-primary">
                                   {getInitials(client?.name)}
                              </Avatar>
                              <div>
                                   <p className="text-lg font-semibold capitalize">{client?.name}</p>
                                   <p className="text-secondaryLight2 capitalize">{client?.account_type}</p>
                              </div>
                         </div>

                         <div className="flex items-center gap-12 mx-4 mb-4 md:px-4">
                              <div>
                                   <p className="font-medium">Email</p>
                                   <p className="font-medium my-2">Phone</p>
                                   <p className="font-medium my-2">Status</p>
                              </div>
                              <div>
                                   <p>{client?.email}</p>
                                   <p className="my-2">{client?.mobile_no} </p>
                                   <p className="my-2">{client?.status}</p>
                              </div>
                         </div>
                    </div>

                    <div className="border rounded-lg">
                         <div className="p-4">
                              <p className="text-lg font-semibold">Client Download Mobile App</p>
                         </div>

                         <div className="border-t mx-4 mb-4 pt-4 flex items-center gap-5 flex-wrap">
                              <button className="h-10">
                                   <img src="/assets/icons/playstore-btn.svg" alt="btn" className="object-contain" />
                              </button>
                              <button className="h-10">
                                   <img src="/assets/icons/appstore-btn.svg" alt="btn" className="object-contain" />
                              </button>
                         </div>
                    </div>
               </div>
          </div>
     );
}
