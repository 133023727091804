import React from "react";
import ReactApexChart from "react-apexcharts";

const ColumnBarChart = ({ categories = [], series = [] }) => {
  const options = {
    chart: {
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        columnWidth: "30%",
        borderRadius: 6,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["transparent"],
    },
    xaxis: {
      categories: categories,
      labels: {
        style: {
          colors: "#959FA3",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: "#959FA3",
        },
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      theme: "dark", // Use ApexCharts' built-in themes
    },
    colors: ["#1E4387", "#959FA3"],
    legend: {
      show: false,
    },
  };

  return (
    <div>
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={350}
      />
    </div>
  );
};

export default ColumnBarChart;
